import Dashboard from './Dashboard';

const managedPricingRoutes = [
  {
    id: 'managedPricingDashboard',
    index: true,
    element: <Dashboard />,
  },
];

export default managedPricingRoutes;
