import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { Confirm, ToggleInput, WarningIcon } from '../../../../../components';
import { Translate } from '../../../../../i18n/translate';

// Define types for state variables
type ConfirmationState = {
  resetPassword: boolean;
  resetMFA: boolean;
  confirmResetMFA: boolean;
  blockUser: boolean;
  confirmBlockUser: boolean;
  lockUser: boolean;
  confirmLockUser: boolean;
  deleteUser: boolean;
  confirmDeleteUser: boolean;
};

const Settings: React.FC = () => {
  const [confirmationState, setConfirmationState] = useState<ConfirmationState>({
    resetPassword: true,
    resetMFA: false,
    confirmResetMFA: false,
    blockUser: false,
    confirmBlockUser: false,
    lockUser: false,
    confirmLockUser: false,
    deleteUser: false,
    confirmDeleteUser: false,
  });

  const [logOutChecked, setLogOutChecked] = useState<boolean>(false);
  const handleCancel = (key: keyof ConfirmationState) => {
    setConfirmationState((prevState) => ({ ...prevState, [key]: false }));
  };

  const handleConfirm = (key: keyof ConfirmationState) => {
    setConfirmationState((prevState) => ({ ...prevState, [key]: false }));
  };

  return (
    <>
      <Confirm
        headerIcon={<WarningIcon />}
        title='You are about to reset the password on behalf of this user via Okta'
        message='Click to receive a verification code on your Okta mobile app'
        onCancel={() => handleCancel('resetPassword')}
        onConfirm={() => handleConfirm('resetPassword')}
        confirmLabel='Send Okta code'
        visible={confirmationState.resetPassword}
        checkboxLabel='Log this user out of all accounts'
        checkboxChecked={logOutChecked}
        onCheckboxChange={setLogOutChecked}
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='You are about to reset the user’s MFA from MFA1 to MFA2'
        message='Please send a request for your admin to confirm this'
        onCancel={() => handleCancel('resetMFA')}
        onConfirm={() => handleConfirm('resetMFA')}
        confirmLabel='Send request to admin'
        visible={confirmationState.resetMFA}
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='MFA Reset Request send successfully'
        message='You have now sent a request to the admin to reset your MFA from MFA1 to MFA2 successfully'
        onConfirm={() => handleConfirm('confirmResetMFA')}
        visible={confirmationState.confirmResetMFA}
        singleButton='Done'
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='You are about to block Test Username’s account'
        message='Please submit a request to your admin to confirm this'
        onCancel={() => handleCancel('blockUser')}
        onConfirm={() => handleConfirm('blockUser')}
        confirmLabel='Send request to admin'
        visible={confirmationState.blockUser}
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='The request to block username Test User has been sent successfully'
        message='The action will be completed upon confirmation from the admin'
        onConfirm={() => handleConfirm('confirmBlockUser')}
        visible={confirmationState.confirmBlockUser}
        singleButton='Done'
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='You are about to lock Test Username’s account'
        message='Please send a request to the admin to confirm this'
        onCancel={() => handleCancel('lockUser')}
        onConfirm={() => handleConfirm('lockUser')}
        confirmLabel='Send request to admin'
        visible={confirmationState.lockUser}
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='The request to lock the account for username Test User has been sent successfully'
        message='The action will be completed upon confirmation from the admin'
        onConfirm={() => handleConfirm('confirmLockUser')}
        visible={confirmationState.confirmLockUser}
        singleButton='Done'
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='You are about to delete Test Username’s account'
        message='Please submit a request to your admin to confirm this'
        onCancel={() => handleCancel('deleteUser')}
        onConfirm={() => handleConfirm('deleteUser')}
        confirmLabel='Send request to admin'
        visible={confirmationState.deleteUser}
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='The request to delete the account for username Test User has been sent successfully'
        message='The action will be completed upon confirmation from the admin'
        onConfirm={() => handleConfirm('confirmDeleteUser')}
        visible={confirmationState.confirmDeleteUser}
        singleButton='Done'
      />

      <div className='!p-8 card'>
        <div>
          <div className='text-sm-bold text-secondary-text'>
            <Translate value='securitySettings' />
          </div>
          <div className='text-neutral-3'>
            <Translate value='securitySettingsDescription' />
          </div>
        </div>
        <div className='flex gap-[30px] mt-8'>
          <ToggleInput id='id' label='blockUser' checked={false} className='flex gap-[30px]' />
          <ToggleInput id='id' label='lockAccount' checked={true} className='flex gap-[30px]' />
        </div>
      </div>

      <div className='!p-8 mt-8 card'>
        <div>
          <div className='text-sm-bold text-secondary-text'>
            <Translate value='resetSecurity' />
          </div>
          <div className='text-neutral-3'>
            <Translate value='resetSecurityDescription' />
          </div>
        </div>
        <div className='mt-4 flex gap-8'>
          <Button severity='contrast' className='!px-7'>
            <Translate value='resetPassword' />
          </Button>
          <Button severity='contrast' className='!px-7'>
            <Translate value='resetMFA' />
          </Button>
        </div>
      </div>

      <div className='!p-8 mt-8 card'>
        <div>
          <div className='text-sm-bold text-secondary-text'>
            <Translate value='deleteAccount' />
          </div>
          <div className='text-neutral-3'>
            <Translate value='deleteAccountDescription' />
          </div>
        </div>
        <Button severity='danger' className='!px-7 mt-[30px]'>
          <Translate value='deleteAccount' />
        </Button>
      </div>

      <div className='flex items-center justify-center mt-10 gap-5'>
        <Button severity='contrast' className='!px-7'>
          <Translate value='cancel' />
        </Button>
        <Button type='submit' severity='info' className='!px-7'>
          <Translate value='save_and_proceed' />
        </Button>
      </div>
    </>
  );
};

export default Settings;
