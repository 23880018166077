import { useEffect, useState } from 'react';
import { useMatches, useNavigate, useSearchParams } from 'react-router-dom';

export default function Breadcrumb() {
  const matches = useMatches();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [parent, setParent] = useState<string | null>('');

  useEffect(() => {
    if (searchParams.get('sb') === 'c') {
      setParent(searchParams.get('name'));
    } else {
      setParent('');
    }
  }, [searchParams]);

  const items = matches
    .filter((match) => (match.handle as { breadcrumb?: string })?.breadcrumb)
    .map((match, index, array) => {
      const breadcrumb = (match.handle as { breadcrumb?: string })?.breadcrumb || '';
      const isLast = index === array.length - 1;

      return (
        <div key={match.pathname} className='flex items-center mb-1.5'>
          <i className='pi pi-chevron-right text-neutral-3 mr-3 text-sm mt-px'></i>
          <button
            onClick={() => navigate(match.pathname)}
            className={`text-lg ${isLast ? 'text-primary font-semibold' : 'text-neutral-3'}`}
            disabled={isLast}
          >
            {breadcrumb}
          </button>
        </div>
      );
    });

  if (parent) {
    items.unshift(
      <div key='parent' className='flex items-center mb-1.5'>
        <i className='pi pi-chevron-right text-neutral-3 mr-3 text-sm mt-px'></i>
        <button onClick={() => navigate('/')} className='text-lg text-neutral-3'>
          {parent}
        </button>
      </div>,
    );
  }

  return (
    <div className='flex items-center space-x-3 p-5'>
      {/* Back Button */}
      {items.length > 1 && (
        <button className='text-primary' onClick={() => navigate(-1)}>
          <i className='pi pi-chevron-left w-6 h-6 text-primary mr-5 ml-5'></i>
        </button>
      )}
      {/* Home Icon */}
      <button onClick={() => navigate('/')} className={`mr-5 ${items.length < 2 ? 'ml-5' : ''}`}>
        <i className='pi pi-home text-neutral-3 text-[20px]'></i>
      </button>
      {/* Breadcrumb Items */}
      {items}
    </div>
  );
}
