import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Controller } from 'react-hook-form';
import { translateWithValues } from '../../i18n/translate';
import ErrorIcon from '../ErrorIcon';

interface DropdownProps {
  id?: string;
  label: string;
  placeholder?: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  className?: string;
  value?: string;
  name?: string;
  control?: any;
  error?: any;
  options?: any;
  rules?: any;
  disabled?: boolean;
  onChange?: (e: DropdownChangeEvent, name: string) => void;
}

const DropdownInput = ({
  label,
  id,
  isRequired,
  ariaDescribedBy,
  placeholder,
  className,
  value,
  rules,
  name,
  error,
  options,
  control,
  disabled,
  onChange,
}: DropdownProps) => {
  const errorMessage =
    error &&
    (error.type === 'required'
      ? `${translateWithValues(label)} ${translateWithValues('is_required')}`
      : error.message);
  return (
    <div className={`flex flex-col gap-1 ${className || ''}`}>
      <label htmlFor={id} className='whitespace-nowrap overflow-hidden text-ellipsis inline-block'>
        {translateWithValues(label)} {isRequired ? <span className='text-error-1'>*</span> : ''}
      </label>
      <IconField iconPosition='right'>
        {control && name ? (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Dropdown
                id={id}
                tooltip={error && errorMessage}
                name={name}
                disabled={disabled}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={options}
                placeholder={placeholder && translateWithValues(placeholder)}
                invalid={!!error}
              />
            )}
          />
        ) : (
          <Dropdown
            id={id}
            name={name}
            value={value}
            aria-describedby={ariaDescribedBy}
            placeholder={placeholder && translateWithValues(placeholder)}
            options={options}
            onChange={(e) => {
              if (onChange) {
                onChange(e, name || '');
              }
            }}
            invalid={!!error}
          />
        )}

        {!!error && (
          <InputIcon className='mr-[23px]'>
            <ErrorIcon isFilled={false} size={16} withBackground={false} />
          </InputIcon>
        )}
      </IconField>
    </div>
  );
};

export default DropdownInput;
