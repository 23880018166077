import apiClient from '../../../utils/apiClient';
import { handleApiResponse } from '../../../utils/helper';

const CUSTOMER_ROLES = 'api/customer-role-management/customer-roles';
const ROLES = 'api/customer-role-management/roles';
const ASSIGN_USERS = 'api/customer-role-management/roles/assign-users';

const RoleService = {
  async getCustomerRoles(customerId: string | number | undefined): Promise<RoleResponse> {
    try {
      const response = await apiClient.get(`${CUSTOMER_ROLES}/${customerId}`);
      return handleApiResponse<RoleResponse>(response);
    } catch (error) {
      console.error('Error fetching customer roles:', error);
      return {
        primaryRoles: [],
        customRoles: [],
      };
    }
  },
  async getRoleUsersById(roleId: string | number): Promise<User | null> {
    try {
      const response = await apiClient.get(`${ROLES}/${roleId}/users`);
      return handleApiResponse<User>(response);
    } catch (error) {
      console.error(`Error fetching users for the roleid - ${roleId}`, error);
      return null;
    }
  },
  assignUsersToRole(data: AssignRolePayload) {
    return apiClient.post(`${ASSIGN_USERS}`, data);
  },
};

export default RoleService;
