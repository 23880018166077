import { useSelector } from 'react-redux';
import PrimaryUsers from './PrimaryUsers';
import { selectSelectedRole } from '../../../../store/slices/role/roleSlice';
import RoleDefinitionCard from './RoleDefinitionCard';
import { useEffect } from 'react';
import RoleService from '../roleService';

const PrimaryUsersView = () => {
  const role = useSelector(selectSelectedRole);

  useEffect(() => {
    fetchUsersForRole();
  }, [role]);

  const fetchUsersForRole = async () => {
    if (!role) {
      return;
    }

    const users = await RoleService.getRoleUsersById(role.id);
    console.log(users);
  };

  const users = [
    { name: 'Abc Doe', email: 'johndoe@example.com' },
    { name: 'Efg Doe', email: 'janedoe@example.com' },
    { name: 'Hij Doe', email: 'janedoe@example.com' },
    { name: 'Klm Doe', email: 'janedoe@example.com' },
    { name: 'Nop Doe', email: 'janedoe@example.com' },
    { name: 'Qrs Doe', email: 'janedoe@example.com' },
    { name: 'Tuv Doe', email: 'janedoe@example.com' },
    { name: 'Wxy Doe', email: 'janedoe@example.com' },
    { name: 'Z Doe', email: 'janedoe@example.com' },
    { name: 'Jane Abc', email: 'janedoe@example.com' },
    { name: 'Jane Def', email: 'janedoe@example.com' },
    { name: 'Jane Hij', email: 'janedoe@example.com' },
    { name: 'Jane Klm', email: 'janedoe@example.com' },
    { name: 'Jane Nop', email: 'janedoe@example.com' },
    { name: 'Jane Qrs', email: 'janedoe@example.com' },
    { name: 'Jane Tuv', email: 'janedoe@example.com' },
    { name: 'Jane Wxy', email: 'janedoe@example.com' },
    { name: 'Jane Z', email: 'janedoe@example.com' },
    { name: 'Jane Doe', email: 'janedoe@example.com' },
  ];

  if (!role) {
    return <></>;
  }

  return (
    <>
      <RoleDefinitionCard role={role} />
      <PrimaryUsers users={users} />
    </>
  );
};

export default PrimaryUsersView;
