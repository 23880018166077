import { DisclosureType } from '../../types';

const CURRENCY_ACTIONS = [
  {
    name: 'buy_currency',
    value: 'buy',
  },
  {
    name: 'sell_currency',
    value: 'sell',
  },
];

const DISCLOSURE_TYPE_OPTIONS = [
  {
    label: 'Dodd Frank: Prepayment Disclosure',
    value: DisclosureType.PREPAYMENT_DISCLOSURE,
  },
  {
    label: 'Dodd Frank: Receipt Disclosure',
    value: DisclosureType.RECEIPT_DISCLOSURE,
  },
];

export { CURRENCY_ACTIONS, DISCLOSURE_TYPE_OPTIONS };
