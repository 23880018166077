import {
  CloseOutline,
  Filter,
  Help,
  TrashCan,
  UserFollow,
  UserIdentification,
  UserMultiple,
  UserProfile,
} from '@carbon/icons-react';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Confirm,
  CurrencyFlag,
  InheritedMenu,
  PaginatorTemplate,
  Status,
  TableSearch,
  WarningIcon,
} from '../../../components';
import FilterModal from '../../../components/FilterModal';
import { Translate } from '../../../i18n/translate';
import { Download, MoreAction } from '../../../icons';
import { enumToCamelCase } from '../../../utils/helper';
import {
  CUSTOMER_CURRENCY_OPTIONS,
  CUSTOMER_TYPE_OPTIONS,
  STATUS_OPTIONS,
} from '../clientConstants';
import ClientService from '../clientService';

const Table = ({ clients, getClients }: { clients: Client[]; getClients: () => Promise<void> }) => {
  const [selectedClients, setSelectedClients] = useState<Client[] | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentActionClient, setCurrentActionClient] = useState<Client | null>(null);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null || '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [isModalVisible, setModalVisible] = useState(false);

  const menu = useRef<Menu>(null);
  const navigate = useNavigate();

  const filterValues = [
    {
      header: 'By Status',
      fields: [
        {
          name: 'status',
          placeholder: 'Select Status',
          value: [],
          options: STATUS_OPTIONS,
        },
      ],
    },
    {
      header: 'By Currency',
      fields: [
        {
          name: 'currency',
          placeholder: 'Select Currency',
          value: [],
          options: CUSTOMER_CURRENCY_OPTIONS,
        },
      ],
    },
    {
      header: 'By Customer Type',
      fields: [
        {
          name: 'customer_type',
          placeholder: 'Select Customer Currency',
          value: [],
          options: CUSTOMER_TYPE_OPTIONS,
        },
      ],
    },
  ];

  const handleApplyFilters = (formdata: { [key: string]: string[] }) => {
    console.log('Applied Filters:', formdata);
    setModalVisible(false);
  };
  
  const actions: MenuItem[] = [
    {
      label: 'editClient',
      icon: <UserProfile />,
      onClick: () => {
        navigate(`/client/edit/${currentActionClient?.id}`);
      },
    },
    {
      label: 'addNewClient',
      icon: <UserFollow />,
      onClick: () => {
        navigate(
          `${currentActionClient?.id}/add?sb=c&name=${currentActionClient?.customerShortName}`,
        );
      },
    },
    {
      label: 'viewUsers',
      icon: <UserMultiple />,
      onClick: () => {
        navigate(
          `/manage/user/${currentActionClient?.id}?sb=c&name=${currentActionClient?.customerShortName}`,
        );
      },
    },
    {
      label: 'viewClients',
      onClick: () => {
        navigate(
          `${currentActionClient?.id}/view?sb=c&name=${currentActionClient?.customerShortName}`,
        );
      },
      icon: <UserIdentification />,
    },
    {
      label: 'deactivate',
      icon: <CloseOutline />,
    },
    {
      label: 'delete',
      icon: <TrashCan />,
      onClick: () => {
        setShowDeleteDialog(true);
      },
      className: 'delete-menu-item',
    },
  ];

  const deleteClient = async (client: Client) => {
    if (client.id) {
      await ClientService.delete(client.id);
    }
    getClients();
  };

  const actionBodyTemplate = (client: Client) => {
    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActionClient(client);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={actions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div className='flex justify-between'>
      <div>
        <div className='text-neutral-1 text-lg-semibold'>
          Total
          <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
            {clients.length}
          </span>
        </div>
        <div className='mt-[15px]'>
          <Translate
            value='client_list_table_description'
            className='text-neutral-3 text-sm-regular'
          />
        </div>
      </div>

      <div className='flex justify-around'>
        <div>
          <TableSearch
            globalFilterValue={globalFilterValue}
            onGlobalFilterChange={onGlobalFilterChange}
          />
        </div>

        <div className='ml-2'>
          <Button
            severity='secondary'
            className='p-button-text'
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <Filter /> <Translate value='filterLabel' className='ml-1' />
          </Button>
        </div>
        <div className='ml-2'>
          <Button
            severity='contrast'
            onClick={() => {
              //TODO open filter
            }}
          >
            <Download /> <Translate value='downloadLabel' className='ml-1' />
          </Button>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Confirm
        confirmLabel='Delete'
        headerIcon={<WarningIcon />}
        title={`Delete '${currentActionClient?.customerShortName}'?`}
        message='You are about to delete this client from the list. You cannot recover the set up details once deleted. Are you sure you want to delete this client?'
        confirmButtonSeverify='danger'
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          if (currentActionClient) {
            deleteClient(currentActionClient);
          }
          setShowDeleteDialog(false);
        }}
        visible={showDeleteDialog}
      />
      <FilterModal
        visible={isModalVisible}
        setFilterModalToggle={setModalVisible}
        filters={filterValues}
        title='clientFilter'
        onApply={handleApplyFilters}
      />
      {clients.length != 0 && (
        <div className='table-card'>
          <DataTable
            paginator={true}
            paginatorTemplate={PaginatorTemplate}
            first={0}
            rows={10}
            totalRecords={clients.length}
            header={header}
            value={clients}
            size={'large'}
            selectAll={true}
            selectionMode={'checkbox'}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selection={selectedClients!}
            onSelectionChange={(e: { value: Client[] }) => setSelectedClients(e.value)}
            dataKey='id'
            globalFilterFields={[
              'client',
              'customerShortName',
              'status',
              'currency',
              'customerType',
            ]}
            filters={filters}
          >
            <Column selectionMode='multiple' headerStyle={{ width: '1rem' }}></Column>
            <Column
              field='client'
              header='Client Name'
              body={(client: Client) => (
                <div className='text-sm-medium text-neutral-2'>
                  {client.customerLegalName}
                  <div className='text-sm-regular text-neutral-3'>{client.email}</div>
                </div>
              )}
            ></Column>
            <Column
              field='customerShortName'
              header={
                <span>
                  Short Name <Help className='ml-1 inline text-neutral-4' />
                </span>
              }
            ></Column>
            <Column
              sortable
              field='status'
              header='Status'
              body={(client: Client) => <Status status={client.status} />}
            ></Column>
            <Column
              filterField='currency'
              header='Currency'
              body={(client: Client) => <CurrencyFlag currencyCode={client.currency ?? ''} />}
            ></Column>
            <Column
              field='customerType'
              header='Customer Type'
              body={(client: Client) => (
                <div className='text-sm-medium text-neutral-2'>
                  {enumToCamelCase(client.customerType)}
                </div>
              )}
            ></Column>

            <Column field='nnemonicId' header='Mnemonic ID'></Column>
            <Column header='Actions' body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
      )}
    </>
  );
};

export default Table;
