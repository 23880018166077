import { ColorPicker } from 'primereact/colorpicker';
import { TextInput } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { CheckMark } from '../../../../icons';
import './index.css';

const ColorDetails = () => {
  const [colors, setColors] = useState<ColorsFormData>({});
  const [isColorsVisible, setIsColorsVisible] = useState(false);

  const handleChange = (e: any, name: string) => {
    setColors({ ...colors, [name]: e.target.value });
  };

  const colorConfig: ColorConfig[] = [
    {
      name: 'background',
      placeholder: 'color.background',
    },
    {
      name: 'banner',
      placeholder: 'color.banner',
    },
    {
      name: 'errorMessage',
      placeholder: 'color.error_message',
    },
    {
      name: 'inputField',
      placeholder: 'color.input_field',
    },
    {
      name: 'inputFieldFocus',
      placeholder: 'color.input_field_focus',
    },
    {
      name: 'legalText',
      placeholder: 'color.legal_text',
    },
    {
      name: 'secondaryButton',
      placeholder: 'color.secondary_button',
    },
    {
      name: 'secondaryButtonHover',
      placeholder: 'color.secondary_button_hover',
    },
    {
      name: 'submitButton',
      placeholder: 'color.submit_button',
    },
    {
      name: 'submitButtonHover',
      placeholder: 'color.submit_button_hover',
    },
  ];

  return (
    <div className='flex justify-between'>
      <div className='shadow-c w-[90%] rounded-c8 py-8 px-20 flex flex-col gap-4'>
        {isColorsVisible ? (
          <div>
            <div className='w-[80%] flex flex-col gap-2'>
              {colorConfig.map(({ name, placeholder }, index) => (
                <div className='flex items-center justify-between flex-col lg:flex-row' key={index}>
                  <Translate value={placeholder} className='color-label' />
                  <div className='flex items-center gap-4'>
                    <TextInput
                      placeholder={placeholder}
                      value={colors[name]}
                      onChange={(e) => handleChange(e, name)}
                    />
                    <ColorPicker value={colors[name]} onChange={(e) => handleChange(e, name)} />
                  </div>
                </div>
              ))}
            </div>
            <div className='pt-8 text-center'>
              <Button severity='info'>
                <Translate value='save_and_proceed' />
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className='text-md-semibold'>
              <Translate value='set_colors' />
            </div>
            <div>
              <Button
                severity='contrast'
                className='!px-14'
                onClick={() => {
                  setIsColorsVisible(true);
                }}
              >
                <Translate value='start' />
              </Button>
            </div>
          </>
        )}
      </div>
      <div>
        <CheckMark />
      </div>
    </div>
  );
};

export default ColorDetails;
