import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CurrencySelect, DropdownInput, TextInput } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { FEES_TYPE_OPTIONS } from '../Dashboard/formConstants';
import ManagePricingService from '../managePricingService';
import './../index.css';

const CreateFees = ({
  visible,
  setCreateFeesToggle,
  editEntity,
}: {
  visible: boolean;
  editEntity?: Fees;
  setCreateFeesToggle: (visible: boolean) => void;
}) => {
  const {
    setValue,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<CreateFeeFormData>();
  const formRef = useRef(null);
  const currentCustomer = useSelector(selectCurrentCustomer);

  useEffect(() => {
    if (editEntity) {
      setValue('feeType', editEntity.feeType);
      setValue('description', editEntity.description);
      setValue('currency', editEntity.currency);
      setValue('feeCurrency', editEntity.feeCurrency);
      setValue('amount', editEntity.amount);
    }
  }, [editEntity]);

  const headerElement = () => (
    <div className='flex justify-between items-center w-full'>
      <div className='flex flex-col gap-1'>
        <div className='text-lg font-semibold text-neutral-1'>
          <Translate value='createFeesTitle' />
        </div>
        <div className='text-sm font-regular text-neutral-3'>
          <Translate value='createFeesDescription' className='!text-sm-regular' />
        </div>
      </div>
    </div>
  );

  const onSave = async (data: CreateFeeFormData) => {
    if (currentCustomer?.id) {
      const updateData = { ...data };

      if (!editEntity) {
        await ManagePricingService.createFee(currentCustomer.id, updateData);
      } else {
        await ManagePricingService.updateFee(editEntity.id, updateData);
      }
      setCreateFeesToggle(false);
    }
  };

  const footerContent = (
    <div className='flex justify-end mt-5 gap-2'>
      <Button severity='secondary' onClick={() => setCreateFeesToggle(false)}>
        <Translate value='cancel' />
      </Button>
      <Button
        severity='info'
        onClick={() => {
          if (formRef.current) {
            (formRef.current as HTMLFormElement).dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true }),
            ); // Manually trigger form submit
          }
        }}
      >
        <Translate value='save' />
      </Button>
    </div>
  );

  return (
    <Dialog
      className='w-[30%] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={headerElement}
      visible={visible}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      onHide={() => setCreateFeesToggle(false)}
      footer={footerContent}
    >
      {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(onSave)} ref={formRef}>
          <DropdownInput
            label='feeType'
            placeholder='marginType'
            name='feeType'
            isRequired
            control={control}
            options={FEES_TYPE_OPTIONS}
            rules={{ required: true }}
            error={errors['feeType']}
          />

          <TextInput
            control={control}
            className='mt-[32px]'
            label='description'
            placeholder='description'
            isRequired
            formRegister={register('description', { required: true })}
            error={errors['description']}
          />

          <CurrencySelect
            includeAll={true}
            control={control}
            className='mt-[32px] w-full'
            name='currency'
            label='currency'
            placeholder='ccy'
            rules={{ required: true }}
            error={errors['currency']}
            isRequired
          />

          <CurrencySelect
            includeAll={true}
            control={control}
            className='mt-[32px] w-full'
            name='feeCurrency'
            label='feeCurrency'
            placeholder='feeCurrency'
            rules={{ required: true }}
            error={errors['feeCurrency']}
            isRequired
          />

          <TextInput
            control={control}
            className='mt-[32px]'
            name='amount'
            label='amount'
            placeholder='amount'
            isRequired
            formRegister={register('amount', { required: true })}
            error={errors['amount']}
          />
        </form>
      }
    </Dialog>
  );
};

export default CreateFees;
