import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';

export interface MainState {
  error: string;
  isLoading: boolean;
  userPrinciple: UserPrinciple | null;
  currentCustomer: Client | null;
}

export const initialState: MainState = {
  error: '',
  isLoading: false,
  userPrinciple: null,
  currentCustomer: null,
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setError: (state: MainState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setLoading: (state: MainState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUserPrinciple: (state: MainState, action: PayloadAction<UserPrinciple>) => {
      state.userPrinciple = action.payload;
      if (state.userPrinciple?.owningCustomers.length == 1) {
        state.currentCustomer = state.userPrinciple.owningCustomers[0];
      }
    },
    setCurrentCustomer: (state: MainState, action: PayloadAction<Client>) => {
      state.currentCustomer = action.payload;   
    },
  },
});

export const { setError, setUserPrinciple, setCurrentCustomer, setLoading } = mainSlice.actions;
export const selectLoading = (state: RootState) => state.main.isLoading;
export const selectUserPrinciple = (state: RootState) => state.main.userPrinciple;
export const selectCurrentCustomer = (state: RootState) => state.main.currentCustomer;
export default mainSlice.reducer;
