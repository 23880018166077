import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { TabPanel, TabView } from 'primereact/tabview';
import { useContext, useEffect, useRef, useState } from 'react';
import { InheritedMenu, PageHead } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { PageHeadContext } from '../../../../providers/PageHead';
import CreateFees from '../AddFees';
import CreateMargin from '../AddMargin';
import Fees from './Fees';
import './index.css';
import Margins from './Margins';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const menu = useRef<Menu>(null);
  const [createMarginToggle, setCreateMarginToggle] = useState(false);
  const [createFeesToggle, setCreateFeesToggle] = useState(false);
  const [requestMRefresh, setMRequestRefresh] = useState(false);
  const [requestFRefresh, setFRequestRefresh] = useState(false);
  const [editMEntity, setEditMEntity] = useState<Margin>();
  const [editFEntity, setEditFEntity] = useState<Fees>();

  const items: MenuItem[] = [
    {
      label: 'Create Margins',
      onClick: () => {
        setCreateMarginToggle(true);
        setMRequestRefresh(false);
      },
    },
    {
      label: 'Create Fees',
      onClick: () => {
        setCreateFeesToggle(true);
        setMRequestRefresh(false);
      },
    },
  ];

  useEffect(() => {
    setPageHeadData({
      title: '',
      description: '',
    });
  }, []);

  return (
    <>
      <CreateMargin
        visible={createMarginToggle}
        editEntity={editMEntity}
        setCreateMarginToggle={(v) => {
          setEditMEntity(undefined);
          setCreateMarginToggle(v);
          setMRequestRefresh(true);
        }}
      />
      <CreateFees
        visible={createFeesToggle}
        editEntity={editFEntity}
        setCreateFeesToggle={(v) => {
          setEditFEntity(undefined);
          setCreateFeesToggle(v);
          setFRequestRefresh(true);
        }}
      />
      <div className='pl-[48px] pr-[40px] py-[16px] bg-white border-b border-neutral-border'>
        <PageHead
          title='managedPricing.dashboard.title'
          description='managedPricing.dashboard.description'
          button={
            <Button
              severity='info'
              className='h-[40px]'
              onClick={(event) => {
                menu.current?.toggle(event);
              }}
            >
              <Translate value='createPricingBtn' />
              <InheritedMenu items={items} ref={menu} popupAlign='right' />
            </Button>
          }
        />
      </div>
      <div className='managed-pricing'>
        <div className='p-4 pl-[48px] pr-[35px]'>
          <TabView className='stone-tabs justify-start'>
            <TabPanel header='Margins'>
              <Margins
                refreshData={requestMRefresh}
                onEdit={(margin) => {
                  setCreateMarginToggle(true);
                  setEditMEntity(margin);
                }}
              />
            </TabPanel>
            <TabPanel header='Fees'>
              <Fees
                refreshData={requestFRefresh}
                onEdit={(fees) => {
                  setCreateFeesToggle(true);
                  setEditFEntity(fees);
                }}
              />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
