import apiClient from '../../../../utils/apiClient';
import { handleApiResponse } from '../../../../utils/helper';

const ROOT = '/api/currency-management/currencies/customers/';
const BUY_CURRENCIES = '/buying';
const SELL_CURRENCIES = '/settlement';
const ENABLE_CURRENCY = '/enable';
const DISABLE_CURRENCY = '/disable';

const CurrencyService = {
  async getCurrencies(id: number): Promise<CurrencyConfiguration[]> {
    try {
      const response = await apiClient.get(`${ROOT}${id}`);
      return handleApiResponse<CurrencyConfiguration[]>(response);
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },

  async postBuyCurrencies(id: number, data: any): Promise<Currency[]> {
    try {
      const response = await apiClient.post(`${ROOT}${id}${BUY_CURRENCIES}`, data);
      return handleApiResponse<Currency[]>(response);
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },

  async getBuyCurrencies(id: number): Promise<string[]> {
    try {
      const response = await apiClient.get(`${ROOT}${id}${BUY_CURRENCIES}`);
      return handleApiResponse<string[]>(response);
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },

  async postSellCurrencies(id: number, data: any): Promise<Currency[]> {
    try {
      const response = await apiClient.post(`${ROOT}${id}${SELL_CURRENCIES}`, data);
      return handleApiResponse<Currency[]>(response);
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },

  async getSellCurrencies(id: number): Promise<string[]> {
    try {
      const response = await apiClient.get(`${ROOT}${id}${SELL_CURRENCIES}`);
      return handleApiResponse<string[]>(response);
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },

  async enableCurrency(id: number, data: any): Promise<Currency[]> {
    try {
      const response = await apiClient.post(`${ROOT}${id}${ENABLE_CURRENCY}`, data);
      return handleApiResponse<Currency[]>(response);
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },

  async disableCurrency(id: number, data: any): Promise<Currency[]> {
    try {
      const response = await apiClient.post(`${ROOT}${id}${DISABLE_CURRENCY}`, data);
      return handleApiResponse<Currency[]>(response);
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },
};

export default CurrencyService;
