import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/customer-management/customers';
const VALIDATE_CLIENT = '/validateOnCreate';
const USER_ROOT = '/api/user-management/users';
const STONEX_CLIENT = '/api/customer-backoffice/customers';

const ClientService = {
  async getClients(): Promise<Client[]> {
    try {
      const response = await apiClient.get(`${ROOT}`);
      return handleApiResponse<Client[]>(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
      return [];
    }
  },

  async getChildClients(childCustomerId: string): Promise<Client[]> {
    try {
      const response = await apiClient.get(`${ROOT}/${childCustomerId}/child-customers`);
      return handleApiResponse<Client[]>(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
      return [];
    }
  },

  async getClientById(id: string | number): Promise<Client | null> {
    try {
      const response = await apiClient.get(`${ROOT}/${id}`);
      return handleApiResponse<Client | null>(response);
    } catch (error) {
      console.error(`Error fetching client with id ${id}:`, error);
      return null;
    }
  },

  validateOnCreate(client: Client) {
    return apiClient.post(`${ROOT}${VALIDATE_CLIENT}`, client);
  },

  createAccount(client: Client) {
    return apiClient.post(`${ROOT}`, client);
  },

  async uploadFile(file: File): Promise<FileUploadResponse | null> {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', file.type);
      const response = await apiClient.post(
        '/api/branding-management/branding/customers/1',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return handleApiResponse(response);
    } catch (err) {
      console.error(`Error uploading file ${file.name}`);
      return null;
    }
  },

  updateCustomer(users: Client, customerId: string) {
    return apiClient.put(`${ROOT}/${customerId}`, users);
  },

  async createUsers(users: User[]) {
    try {
      const response = await apiClient.post(USER_ROOT, users);
      return response.data;
    } catch (error) {
      console.log('Errror saving user details');
      return null;
    }
  },

  delete(id: number) {
    return apiClient.delete(`${ROOT}/customers/${id}`);
  },

  async getCustomerByStonexClientId(stonexClientId: string): Promise<StonexClient[] | null> {
    try {
      const response = await apiClient.get(`${STONEX_CLIENT}/${stonexClientId}/records`);
      return handleApiResponse<StonexClient[] | null>(response, 'value');
    } catch (error) {
      console.error(`Error fetching client with id ${stonexClientId}:`, error);
      return null;
    }
  },

  async getUsersByCustomerId(customerId: string): Promise<User[] | null> {
    try {
      const response = await apiClient.get(`${USER_ROOT}/customers/${customerId}`);
      return handleApiResponse<User[] | null>(response, 'content');
    } catch (error) {
      console.error('Error fetching client with id', error);
      return null;
    }
  },
};

export default ClientService;
