import { AddOrEdit } from '../../../types';
import AddUser from './AddUser';
import Dashboard from './Dashboard';

const userRoutes = [
  {
    id: 'userDashboard',
    index: true,
    element: <Dashboard />,
  },
  {
    id: 'usersWithCustomer',
    index: true,
    path: ':customerId',
    element: <Dashboard />,
  },
  {
    id: 'addUser',
    path: 'add',
    element: <AddUser mode={AddOrEdit.Add} />,
    handle: {
      breadcrumb: 'Add New User',
    },
  },
  {
    id: 'Add user to customer',
    path: ':customerId/add',
    element: <AddUser mode={AddOrEdit.Add} />,
    handle: {
      breadcrumb: 'Add New User',
    },
  },
  {
    id: 'editUser',
    path: 'edit/:userId',
    element: <AddUser mode={AddOrEdit.Edit} />,
    handle: {
      breadcrumb: 'Edit User',
    },
  },
];

export default userRoutes;
