import React, { useEffect, useState } from 'react';
import { Translate } from '../../../../../i18n/translate';
import { Progress, RoleCard } from '../../../../../components';
import { BlueInfo, CheckMark } from '../../../../../icons';
import { Button } from 'primereact/button';
import PermissionModal from '../../../../../components/PermissionModal';
import CustomRoles from '../../../Role/Dashboard/CustomRoles';
import WidgetView from '../../../../../icons/WidgetView';
import BlotterView from '../../../../../icons/BlotterView';
import Table from './table';
import './index.css';
import { useParams } from 'react-router';
import UserService from '../../../userService';
import { useSelector } from 'react-redux';
import { selectCurrentCustomer } from '../../../../../store/slices/main/mainSlice';

interface RolesAssignedProps {
  isEdit: boolean;
}

const RolesAssigned = ({ isEdit }: RolesAssignedProps) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [createCustomRole, setCreateCustomRole] = useState(false);
  const [widgetView, setWidgetView] = useState(true);
  const { userId } = useParams<{ userId?: string }>();
  const [primaryRoles, setPrimaryRoles] = useState<Role[]>([]);
  const [customRoles, setCustomRoles] = useState<Role[]>([]);
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const [selectedPrimaryRoles, setSelectedPrimaryRoles] = useState<number[]>([]);
  const [selectedCustomRoles, setSelectedCustomRoles] = useState<number[]>([]);
  const [permission, setPermission] = useState<RolePermission[]>([]);

  const handlePrimarySelectedRole = (roleItem: Role) => {
    setSelectedPrimaryRoles((prevRoles) =>
      prevRoles.includes(roleItem.id)
        ? prevRoles.filter((role) => role !== roleItem.id)
        : [...prevRoles, roleItem.id],
    );
  };

  const handleCustomSelectedRole = (roleItem: Role) => {
    setSelectedCustomRoles((prevRoles) =>
      prevRoles.includes(roleItem.id)
        ? prevRoles.filter((role) => role !== roleItem.id)
        : [...prevRoles, roleItem.id],
    );
  };

  const handleOpenModal = (permission: RolePermission[]) => {
    setPermission(permission);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setPermission([]);
    setModalVisible(false);
  };

  // const permissions = [
  //   { name: 'User Management Permissions' },
  //   { name: 'Currency Widget' },
  //   { name: 'Company Bank Account' },
  //   { name: 'Company Contact' },
  //   { name: 'Indicative Rate Report' },
  //   { name: 'Direct Debit Template' },
  // ];

  const getAllRoles = () => {
    if (selectedCustomer) {
      UserService.getRoleByCustomerId(selectedCustomer.owningCustomerId).then((data: any) => {
        if (data) {
          setPrimaryRoles(data?.primaryRoles);
          setCustomRoles(data?.customRoles);
          getSelectedRoles(data?.primaryRoles, data?.customRoles);
        }
      });
    }
  };

  const getSelectedRoles = (primaryList: Role[], customList: Role[]) => {
    UserService.getRoleByUserId(userId).then((data: any) => {
      if (data) {
        setSelectedPrimaryRoles(
          primaryList
            .filter((role) => data.some((item: any) => item.id == role.id))
            .map((role) => role.id),
        );
        setSelectedCustomRoles(
          customList
            .filter((role) => data.some((item: any) => item.id == role.id))
            .map((role) => role.id),
        );
      }
    });
  };

  const assignRoles = () => {
    const data = {
      userId: userId,
      roleIds: [...selectedPrimaryRoles, ...selectedCustomRoles],
      aoboRoleIds: [],
    };
    UserService.assignRolesToUser(data).then((data) => {
      console.log(data);
    });
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <>
      {/* TODO: Handle role permissions below */}
      <PermissionModal
        visible={isModalVisible}
        onHide={handleCloseModal}
        permissions={permission}
      />
      {createCustomRole ? (
        <CustomRoles />
      ) : (
        <>
          <div className='!p-8 card'>
            <div className='grid grid-cols-2'>
              <div>
                <div className='text-sm-bold text-secondary-text'>
                  <Translate
                    value={
                      isEdit ? 'editUser.rolesAssigned.title' : 'add_user.roles_assigned.title'
                    }
                  />
                </div>
                <div className='text-neutral-3'>
                  <Translate
                    value={
                      isEdit
                        ? 'editUser.rolesAssigned.description'
                        : 'add_user.rolesAssigned.description'
                    }
                  />
                </div>
              </div>
              <div className='flex justify-end items-center'>
                <Progress value={50} currentStep={2} stepLimit={3} />
                <CheckMark className='ml-4' />
              </div>
            </div>
            {isEdit && (
              <div className='flex justify-end'>
                <div>
                  <Button
                    className={
                      (widgetView ? '!text-selected' : '!text-unselected') +
                      ' !p-0 mr-2 !border-none'
                    }
                    onClick={() => {
                      setWidgetView(true);
                    }}
                  >
                    <WidgetView />
                  </Button>

                  <Button
                    className={
                      (!widgetView ? '!text-selected' : '!text-unselected') + ' !p-0 !border-none'
                    }
                    onClick={() => {
                      setWidgetView(false);
                    }}
                  >
                    <BlotterView />
                  </Button>
                </div>
              </div>
            )}

            {widgetView ? (
              <div className='flex flex-wrap gap-8 justify-center mt-8 max-w-[1000px] mx-auto'>
                {primaryRoles &&
                  primaryRoles.map((role: Role) => (
                    <RoleCard
                      key={role.roleName}
                      title={role.roleName}
                      description={role.description}
                      selected={selectedPrimaryRoles.includes(role.id)}
                      onSelect={() => handlePrimarySelectedRole(role)}
                      handleOpenModal={handleOpenModal}
                      permissions={role.rolePermissions}
                    />
                  ))}
              </div>
            ) : (
              <div className='mt-8'>
                <Table roles={primaryRoles} />
              </div>
            )}
          </div>

          {customRoles.length > 0 && (
            <div className='!p-8 mt-8 card'>
              <div className='grid grid-cols-2'>
                <div>
                  <div className='text-sm-bold text-secondary-text'>
                    <Translate
                      value={
                        isEdit
                          ? 'editUser.rolesAssigned.customRolesAssigned.title'
                          : 'add_user.roles_assigned.custom_roles_assigned.title'
                      }
                    />
                  </div>
                  <div className='text-neutral-3'>
                    <Translate
                      value={
                        isEdit
                          ? 'editUser.rolesAssigned.customRolesAssigned.description'
                          : 'add_user.roles_assigned.custom_roles_assigned.description'
                      }
                    />
                  </div>
                </div>
              </div>
              {widgetView ? (
                <div className='flex flex-wrap gap-8 justify-center mt-8 max-w-[1000px] mx-auto'>
                  {customRoles.map((role) => (
                    <RoleCard
                      key={role.roleName}
                      title={role.roleName}
                      description={role.description}
                      selected={selectedCustomRoles.includes(role.id)}
                      onSelect={() => handleCustomSelectedRole(role)}
                      handleOpenModal={handleOpenModal}
                      permissions={role.rolePermissions}
                    />
                  ))}
                </div>
              ) : (
                <div className='mt-8'>
                  <Table roles={customRoles} />
                </div>
              )}
            </div>
          )}

          <div className='p-4 mt-8 flex items-center gap-[100px] border-primary-blue-light bg-primary-surface-blue-light border-[1px] rounded-[12px] justify-between'>
            <div className='flex gap-[10px]'>
              <div>
                <BlueInfo />
              </div>
              <div>
                <div className='text-sm-semibold text-primary-blue-dark'>
                  <Translate value='add_user.roles_assigned.custom_role.title' />
                </div>
                <div className='text-sm-regular text-primary-blue-dark'>
                  <Translate value='add_user.roles_assigned.custom_role.description' />
                </div>
              </div>
            </div>
            <div className='!h-fit'>
              <Button
                severity='info'
                onClick={() => {
                  setCreateCustomRole(true);
                }}
              >
                <Translate
                  value='add_user.roles_assigned.create_custom_role'
                  className='whitespace-nowrap'
                />
              </Button>
            </div>
          </div>

          <div className='flex items-center justify-center mt-10 gap-5'>
            <Button severity='contrast' className='!px-7'>
              <Translate value='cancel' />
            </Button>
            <Button severity='info' className='!px-7' onClick={assignRoles}>
              <Translate value='save_and_proceed' />
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default RolesAssigned;
