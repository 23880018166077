import { InputTextarea } from 'primereact/inputtextarea';
import { translateWithValues } from '../../i18n/translate';

interface TextAreaProps {
    id: string;
    label: string;
    label2: string;
    placeholder?: string;
    ariaDescribedBy?: string;
    isRequired?: boolean;
}

const TextArea = ({
  id,
  label,
  label2,
  placeholder,
  ariaDescribedBy,
  isRequired,
}: TextAreaProps) => (
  <div className='flex flex-col gap-1'>
    <label htmlFor='username'>
      {translateWithValues(label)} {isRequired ? <span className='text-error-1'>*</span> : ''}
    </label>
    <InputTextarea
      autoResize
      id={id}
      placeholder={translateWithValues(placeholder)}
      aria-describedby={ariaDescribedBy}
      rows={7}
    />
    <label htmlFor='username'>{translateWithValues(label2)}</label>
  </div>
);

export default TextArea;