import { AxiosResponse } from 'axios';
import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/user-principal/current-user/';

const UserprincipalService = {
  async getPricnipleCustomers(): Promise<UserPrinciple | null> {
    try {
      const response = await apiClient.get<UserPrinciple>(`${ROOT}customers`);
      return handleApiResponse<UserPrinciple>(response);
    } catch (error) {
      console.error('Error fetching prinicple customers:', error);
      return null;
    }
  },

  async getCurrentUser(): Promise<User | null> {
    try {
      const response = await apiClient.get<User>(`${ROOT}`);
      return handleApiResponse<User>(response);
    } catch (error) {
      console.error('Error fetching current user:', error);
      return null;
    }
  },

  async signOut(): Promise<AxiosResponse | void> {
    try {
      return await apiClient.post(`${ROOT}sign-out`);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  },
};

export default UserprincipalService;
