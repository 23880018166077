import React, { useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { Translate } from '../../../../i18n/translate';
import SearchBar from '../../../../components/SearchInput/SearchInput';
import UserCard from './primaryUserCard';


interface User {
  name: string;
  email: string;
}

interface PrimaryUsersProps {
  users: User[];
}

const PrimaryUsers: React.FC<PrimaryUsersProps> = ({ users }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 9;

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const filteredUsers = useMemo(() => {
    return users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, users]);

  const currentUsers = filteredUsers.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredUsers.length / usersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className='card mt-5'>
      <div className='text-xs-bold text-neutral-1'>
        <Translate value='primaryUsersTitle' />
      </div>
      <div className='text-neutral-3 text-md-regular'>
        <Translate value='primaryUsersDesc' />
      </div>
      <div className='flex justify-between mt-8'>
        <div className='flex items-center space-x-2 p-2 rounded-full bg-primary-surface-lighter'>
          <span className='text-primary font-bold'>Total Users:</span>
          <span className='text-primary font-bold'>{users.length}</span>
        </div>
        <div className='w-[40%]'>
          <SearchBar onSearch={handleSearch} />
        </div>
        <Button severity='info'>
          <Translate value='editUser' />
        </Button>
      </div>
      <div className='flex justify-between items-center mt-8'>
        <div>
          <ChevronLeft size='24' onClick={handlePreviousPage} />
        </div>
        <div className='grid grid-cols-3 gap-4'>
          {currentUsers.map((user, index) => (
            <UserCard key={index} user={user} />
          ))}
        </div>
        <div>
          <ChevronRight size='24' onClick={handleNextPage} />
        </div>
      </div>
    </div>
  );
};

export default PrimaryUsers;