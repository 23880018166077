import { ProgressBar } from 'primereact/progressbar';
import { Translate } from '../../i18n/translate';
import './progress.css';

interface ProgressProps {
  value: number;
  currentStep: number;
  stepLimit: number;
}

const Progress = ({ value, currentStep, stepLimit }: ProgressProps) => (
  <div className='flex items-center'>
    <ProgressBar
      value={value}
      className='custom-progress-bar w-60 h-2'
      displayValueTemplate={() => null}
    />
    <div className='text-neutral-3 ml-4'>
      <Translate value='step' /> {`${currentStep}/${stepLimit}`}
    </div>
  </div>
);

export default Progress;
