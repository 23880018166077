import { Dropdown } from 'primereact/dropdown';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { translateWithValues } from '../../i18n/translate';
import { selectCountries } from '../../store/slices/refdata/refdataSlice';
import ErrorIcon from '../ErrorIcon';
import './CountrySelect.css';
import getCountryISO2 from './isoncCodeMapping';

interface CountryDropdownProps {
  id?: string;
  disabled?: boolean;
  label: string;
  placeholder?: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  className?: string;
  value?: Country;
  name?: string;
  control?: any;
  error?: any;
  rules?: any;
}

const CountrySelect = ({
  label,
  disabled,
  id,
  isRequired,
  ariaDescribedBy,
  placeholder,
  className,
  value,
  rules,
  name,
  error,
  control,
}: CountryDropdownProps) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const selectedCountries = useSelector(selectCountries);

  const selectedCountryTemplate = (option: Country) => {
    if (option) {
      return (
        <div className='flex align-items-center'>
          <img
            alt={option.name}
            src={`/images/country_flags/${getCountryISO2(option.code).toLowerCase()}.svg`}
            className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{placeholder}</span>;
  };

  const countryOptionTemplate = (option: Country) => {
    return (
      <div className='flex align-items-center'>
        <img
          alt={option.name}
          src={`/images/country_flags/${getCountryISO2(option.code).toLowerCase()}.svg`}
          className='mr-2 w-[20px] h-[20px] rounded-full object-cover'
        />
        <div className='overflow-hidden overflow-ellipsis'>{option.name}</div>
      </div>
    );
  };

  useEffect(() => {
    setCountries(selectedCountries);
  }, [selectedCountries]);

  const errorMessage =
    error &&
    (error.type === 'required'
      ? `${translateWithValues(label)} ${translateWithValues('is_required')}`
      : error.message);

  return (
    <div className={`flex flex-col gap-1 ${className || ''}`}>
      <label htmlFor={id}>
        {translateWithValues(label)} {isRequired ? <span className='text-error-1'>*</span> : ''}
      </label>
      <IconField iconPosition='right'>
        {control && name ? (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <Dropdown
                className='w-full'
                id={id}
                tooltip={error && errorMessage}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={countries}
                disabled={disabled}
                optionLabel='name'
                placeholder={placeholder && translateWithValues(placeholder)}
                filter
                valueTemplate={(option: Country) => selectedCountryTemplate(option)}
                itemTemplate={countryOptionTemplate}
                invalid={!!error}
              />
            )}
          />
        ) : (
          <Dropdown
            id={id}
            name={name}
            value={value}
            aria-describedby={ariaDescribedBy}
            placeholder={placeholder && translateWithValues(placeholder)}
            options={selectedCountries}
            optionLabel='name'
            filter
            valueTemplate={selectedCountryTemplate}
            itemTemplate={countryOptionTemplate}
            className='w-full md:w-14rem'
            invalid={!!error}
          />
        )}

        {!!error && (
          <InputIcon className='mr-[23px]'>
            <ErrorIcon isFilled={false} size={16} withBackground={false} />
          </InputIcon>
        )}

      </IconField>
    </div>
  );
};

export default CountrySelect;
