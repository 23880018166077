import { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import SearchBar from '../../../../components/SearchInput/SearchInput';
import { Translate } from '../../../../i18n/translate';
import { ArrowDown, Filter, Grid, Help, List } from '@carbon/icons-react';
import Checkmark from '../../../../icons/Checkmark';
import { MoreAction } from '../../../../icons';
import InfoTemplate from '../../../../components/InfoTemplate';
import PermissionModal from '../../../../components/PermissionModal';

interface Role {
  role: string;
  usersAssigned: string;
  dateAssigned: string;
  status: string;
}

interface CustomTableProps {
  rolesData: Role[];
}

const CustomTable = ({ rolesData }: CustomTableProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isModalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const filteredRoles = rolesData.filter((role) =>
    role.role.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const statusBodyTemplate = (rowData: Role) => {
    const statusClass =
      rowData.status === 'Active'
        ? 'bg-success-bg text-success-content'
        : 'bg-neutral-border text-neutral-1';

    return <div className={`w-20 px-3 py-1 rounded-full ${statusClass}`}>{rowData.status}</div>;
  };

  return (
    <>
      <div className='card'>
        <div className='flex justify-between m-auto'>
          <div>
            <div className='text-xs-bold text-neutral-1'>
              <Translate value='customeUserTableHead' />
            </div>
            <div className='text-neutral-3 text-md-regular'>
              <Translate value='customUserTableDesc' />
            </div>
          </div>
          <div className='flex items-center'>
            <Checkmark type='success' className='ml-4' />
          </div>
        </div>
        <div className='flex justify-between mt-3'>
          <div className='flex items-center space-x-2 p-3 rounded-full bg-primary-surface-lighter'>
            <span className='text-primary font-bold'>
              <Translate value='totalRoles' />:
            </span>
            <span className='text-primary font-bold'>{filteredRoles.length}</span>
          </div>
          <div className='flex gap-2 items-center'>
            <div className='w-40'>
              <SearchBar onSearch={handleSearch} />
            </div>
            <div>
              <div className='flex gap-1 p-2 rounded border cursor-pointer border-neutral-5'>
                <Filter size={20} className='mr-2 text-gray-800' />
                <span className='text-gray-800 font-medium'>
                  <Translate value='filter' />
                </span>
              </div>
            </div>
            <div>
              <Grid size={24} />
            </div>
            <div>
              <List size={24} />
            </div>
            <Button severity='info' onClick={handleOpenModal}>
              <Translate value='assignNewUser' />
            </Button>
          </div>
        </div>
        <div className='mt-5'>
          <DataTable value={filteredRoles} className='p-datatable-gridlines'>
            <Column
              field='role'
              header={
                <span className='text-xs-medium'>
                  <Translate value='role' />
                  <Help className='ml-1 inline text-neutral-4' />
                </span>
              }
              headerStyle={{ borderTopWidth: 0 }}
            ></Column>
            <Column
              field='usersAssigned'
              header={
                <span className='text-xs-medium'>
                  <Translate value='usersAssigned' />
                </span>
              }
              headerStyle={{ borderTopWidth: 0 }}
            ></Column>
            <Column
              field='dateAssigned'
              header={
                <span className='text-xs-medium'>
                  <Translate value='dateAssigned' />
                  <Help className='ml-1 inline text-neutral-4' />
                </span>
              }
              headerStyle={{ borderTopWidth: 0 }}
            ></Column>
            <Column
              field='status'
              header={
                <span className='text-xs-medium'>
                  <Translate value='status' />
                  <ArrowDown className='ml-1 inline text-neutral-4' />
                </span>
              }
              headerStyle={{ borderTopWidth: 0 }}
              body={statusBodyTemplate}
            ></Column>
            <Column
              header={
                <span className='text-xs-medium'>
                  <Translate value='actions' />
                </span>
              }
              headerStyle={{ borderTopWidth: 0 }}
              body={<MoreAction />}
            ></Column>
          </DataTable>
        </div>
      </div>
      <div className='mt-5'>
        <InfoTemplate
          titleKey='select_primary_title'
          descriptionKey='select_primary_desc'
          buttonKey='select_preset_button'
          btnAction={() => console.log('Select')}
        />
      </div>
      <div className='flex items-center justify-center mt-10 gap-5'>
        <Button severity='contrast' className='!px-7'>
          <Translate value='button_cancel' />
        </Button>
        <Button severity='info' className='!px-7'>
          <Translate value='saveChanges' />
        </Button>
      </div>
      <PermissionModal visible={isModalVisible} onHide={handleCloseModal} permissions={[]} />
    </>
  );
};

export default CustomTable;
