import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CurrencySelect, DropdownInput, Progress, TextInput, Toaster } from '../../../components';
import CountrySelect from '../../../components/CountrySelect';
import getCountryISO2 from '../../../components/CountrySelect/isoncCodeMapping';
import { Translate } from '../../../i18n/translate';
import Checkmark from '../../../icons/Checkmark';
import {
  selectCountries,
  selectCustomerCurrencies,
} from '../../../store/slices/refdata/refdataSlice';
import { AddOrEdit, ClientForms, CustomerType } from '../../../types';
import {
  CUSTOMER_TYPE_OPTIONS,
  EMAIL_VALIDATION_REGEX,
  SETTLEMENT_METHOD_OPTIONS,
  STATUS_OPTIONS,
} from '../clientConstants';
import ClientService from '../clientService';

interface CustomerDetailsProps {
  activeStep?: string;
  mode?: AddOrEdit;
  onSaveAndProceed?: () => void;
  setOwningClientid: (owningClientId: number) => void;
  jumpToEdit: (step: string) => void;
  visited?: Set<string>;
  handleStepperValidation: (formName: ClientForms, isValid: boolean) => void;
}

const CustomerDetails = ({
  activeStep,
  onSaveAndProceed,
  jumpToEdit,
  setOwningClientid,
  mode = AddOrEdit.Add,
  visited,
  handleStepperValidation,
}: CustomerDetailsProps) => {
  const [enableEdit, setEnableEdit] = useState(true);
  const [editClient, setEditClient] = useState<Client | null>(null);
  const countriesList = useSelector(selectCountries);
  const currencies = useSelector(selectCustomerCurrencies);
  const { customerId } = useParams();
  const toastRef = useRef<ToasterProps>(null);

  const {
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    formState: { errors, touchedFields, dirtyFields, isValid },
  } = useForm<CustomerDetailsFormData>();

  const customerType = watch('customerType');
  const stonexClientId = watch('stoneXId');
  const formValues = watch();
  const [progress, setProgress] = useState<number>(0);

  const calculateProgress = (): number => {
    const formFields = Object.keys(control._fields);
    const totalFields = formFields.length;
    const validAndTouchedFields = formFields.filter(
      (fieldName) =>
        (touchedFields[fieldName as keyof CustomerDetailsFormData] ||
          dirtyFields[fieldName as keyof CustomerDetailsFormData]) &&
        !errors[fieldName as keyof CustomerDetailsFormData],
    ).length;

    return totalFields > 0 ? Math.round((validAndTouchedFields / totalFields) * 100) : 0;
  };

  useEffect(() => {
    setProgress(calculateProgress());
  }, [formValues, touchedFields, dirtyFields, errors]);

  useEffect(() => {
    if (!customerType) {
      return;
    }
    if (customerType !== CustomerType.INSTRUCTING_INSTITUTION) {
      unregister(['customerUrl', 'stoneXId']);
    }

    if (customerType !== CustomerType.ORDERING_CUSTOMER) {
      unregister(['customerIdentifier', 'bankType', 'bankABA', 'accountNumber']);
    }
    if (customerType === CustomerType.INSTRUCTING_INSTITUTION) {
      unregister(['doddFrank']);
      unregister(['email']);
      unregister(['phone']);
    }
  }, [customerType, unregister]);

  useEffect(() => {
    if (stonexClientId && customerType === CustomerType.INSTRUCTING_INSTITUTION) {
      const stonexClientId = watch('stoneXId');
      getStonexCustomerData(stonexClientId);
      setEnableEdit(false);
    } else setEnableEdit(true);
  }, [stonexClientId, customerType]);

  useEffect(() => {
    if (mode === AddOrEdit.Edit && customerId) {
      getCutomerById();
    }
  }, [mode, customerId]);

  useEffect(() => {
    if (visited?.has(ClientForms.CustomerDetails)) {
      setEnableEdit(false);
    }
  }, [activeStep]);

  const getCutomerById = async () => {
    const client = await ClientService.getClientById(customerId!);
    if (client) {
      setEditClient(client);
      setFormValues(client);
    }
  };

  const setFormValues = (client: Client) => {
    setValue('customerLegalName', client.customerLegalName);
    setValue('customerShortName', client.customerShortName);
    setValue('street1', client.address.street1);
    setValue('street2', client.address.street2);
    setValue('city', client.address.city);
    setValue('state', client.address.state);
    setValue('postalCode', client.address.postalCode);
    setValue(
      'country',
      countriesList.find((country) => country.code === client.address.country) ?? undefined,
    );
    setValue('phone', client.phone);
    setValue('email', client.email);
    setValue('doddFrank', client.isDoddFrank);
    setValue('status', client.status);
    if (client.settlementMethods && client.settlementMethods[0]) {
      setValue('settlementMethod', client.settlementMethods[0]);
    }
    setValue('customerType', client.customerType);
    setValue('stoneXId', client.stoneXId);
    setValue('customerUrl', client.customerUrl);
    const currency = currencies.find((currency) => currency.isocode === client.currency);
    if (currency) {
      setValue('customerCurrency', currency.isocode);
    }
  };

  const getStonexCustomerData = async (stonexClientId: string) => {
    const clients = await ClientService.getCustomerByStonexClientId(stonexClientId);
    if (Array.isArray(clients) && clients.length > 0) {
      const client = clients[0];
      const { Companyconfig } = client;
      const { Basicinfo } = client;
      const { Address } = Basicinfo;
      const country = countriesList.find(
        (country) => getCountryISO2(country.code) === Address.CountryCode,
      );
      setValue('customerLegalName', Basicinfo.Fullname);
      setValue('customerShortName', Basicinfo.Fullname);
      setValue('street1', Address.AddressLines[0]);
      setValue('street2', Address.AddressLines[1]);
      setValue('city', Address.City);
      setValue('state', Address.State);
      setValue('postalCode', Address.PostalCode);
      setValue('country', country ?? undefined);
      setValue('email', Basicinfo.Email);
      setValue('doddFrank', Companyconfig.DoddFrank);
    }
  };

  const saveCustomerDetails = async (customerData: CustomerDetailsFormData) => {
    if (!isValid) {
      return;
    }
    console.log(customerData);
    const submitData: any = {
      ...customerData,
      customerIdentifier: customerData.customerIdentifier,
      stree1: undefined,
      street2: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      customerCurrency: undefined,
      isDoddFrank: customerData.doddFrank,
      settlementMethods: [customerData.settlementMethod],
      currency: customerData.customerCurrency ?? '',
      address: {
        street1: customerData.street1,
        street2: customerData.street2,
        city: customerData.city,
        state: customerData.state,
        postalCode: customerData.postalCode,
        country: customerData.country?.code ?? '',
      },
      bankInfos: [],
    };
    if (mode === AddOrEdit.Add && customerId) {
      submitData.owningCustomerId = customerId; // Add child client
    }
    if (customerType !== CustomerType.INSTRUCTING_INSTITUTION) {
      submitData.bankInfos = [
        {
          accountNumber: customerData.accountNumber,
          routingCode: customerData.bankABA,
          codeType: customerData.bankType,
        },
      ];
    }
    if (mode == AddOrEdit.Add && !visited?.has(ClientForms.CustomerDetails)) {
      const { data, status } = await ClientService.createAccount(submitData as Client);
      if (status !== 200) {
        console.log('error');
        return;
      }
      if (data?.data) {
        setEditClient(data.data as Client);
        setOwningClientid(data.data.id as number);
      }
    } else {
      const clientId: number = editClient?.id ?? 0;
      const updateClient = { ...submitData, id: clientId };
      const { data, status } = await ClientService.updateCustomer(
        updateClient as Client,
        `${editClient?.id}`,
      );
      setOwningClientid(clientId);
      console.log(data, status);
    }

    if (onSaveAndProceed) {
      onSaveAndProceed();
    }
  };

  const showErrorToast = () => {
    toastRef.current?.showToast(
      'error',
      'Please make sure you fill up all the required fields',
      'The set up for this client can only be completed if all the required fields are completed',
    );
  };

  return (
    <>
      <Toaster ref={toastRef} />
      <div className='rounded-c8 shadow-c bg-white p-8'>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='customer.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='customer.description' />
            </div>
          </div>
          <div className='flex justify-end items-center'>
            {activeStep === ClientForms.CustomerDetails && (
              <Progress value={progress} currentStep={1} stepLimit={3} />
            )}
            <Checkmark type={progress === 100 ? 'success' : 'info'} className='ml-4' />
          </div>
        </div>
        {activeStep === ClientForms.CustomerDetails ? (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <form onSubmit={handleSubmit(saveCustomerDetails)}>
            <div className='lg:mx-5 mt-12 mb-6 grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
              <DropdownInput
                label='customer_type'
                placeholder='customer_type'
                name='customerType'
                isRequired
                control={control}
                options={CUSTOMER_TYPE_OPTIONS}
                rules={{ required: true }}
                error={errors['customerType']}
              />
              {customerType === CustomerType.INSTRUCTING_INSTITUTION && (
                <TextInput
                  label='stonexClientId'
                  disabled={!customerType}
                  placeholder='stonexClientId'
                  isRequired
                  formRegister={register('stoneXId', { required: true })}
                  error={errors['stoneXId']}
                />
              )}

              <TextInput
                label='customer_name'
                disabled={!customerType || !enableEdit}
                placeholder='customer_name'
                isRequired
                formRegister={register('customerLegalName', { required: true })}
                error={errors['customerLegalName']}
              />
              <TextInput
                label='customer_short_name'
                disabled={!customerType || !enableEdit}
                placeholder='customer_short_name'
                isRequired
                formRegister={register('customerShortName', { required: true })}
                error={errors['customerShortName']}
              />
              <TextInput
                label='address_line_1'
                disabled={!customerType || !enableEdit}
                placeholder='address_line_1'
                isRequired
                formRegister={register('street1', { required: true })}
                error={errors['street1']}
              />
              <TextInput
                label='address_line_2'
                disabled={!customerType || !enableEdit}
                placeholder='street2'
                formRegister={register('street2')}
              />

              <TextInput
                label='city'
                disabled={!customerType || !enableEdit}
                placeholder='city'
                isRequired
                formRegister={register('city', { required: true })}
                error={errors['city']}
              />
              <TextInput
                label='state'
                disabled={!customerType || !enableEdit}
                placeholder='state'
                formRegister={register('state')}
                error={errors['state']}
              />
              <TextInput
                label='zip_code'
                disabled={!customerType || !enableEdit}
                placeholder='zip_code'
                isRequired
                formRegister={register('postalCode', { required: true })}
                error={errors['postalCode']}
              />
              <CountrySelect
                label='country'
                disabled={!customerType || !enableEdit}
                placeholder='country'
                name='country'
                isRequired
                control={control}
                rules={{ required: true }}
                error={errors['country']}
              />

              {customerType !== CustomerType.INSTRUCTING_INSTITUTION && (
                <>
                  <TextInput
                    label='phone'
                    disabled={!customerType || !enableEdit}
                    placeholder='phone'
                    isRequired
                    formRegister={register('phone', { required: true })}
                    error={errors['phone']}
                  />

                  <TextInput
                    label='email_id'
                    disabled={!customerType || !enableEdit}
                    placeholder='email_id'
                    formRegister={register('email', {
                      required: true,
                      pattern: {
                        value: EMAIL_VALIDATION_REGEX,
                        message: 'invalid_email_address',
                      },
                    })}
                    isRequired
                    error={errors['email']}
                  />
                </>
              )}
              <DropdownInput
                label='status'
                placeholder='status'
                disabled={!customerType}
                name='status'
                isRequired
                control={control}
                options={STATUS_OPTIONS}
                rules={{ required: true }}
                error={errors['status']}
              />
              <DropdownInput
                label='settlement_method'
                placeholder='settlement_method'
                disabled={!customerType}
                name='settlementMethod'
                isRequired
                control={control}
                options={SETTLEMENT_METHOD_OPTIONS}
                rules={{ required: true }}
                error={errors['settlementMethod']}
              />
              {[CustomerType.ORDERING_INSTITUTION, CustomerType.INSTRUCTING_INSTITUTION].includes(
                customerType,
              ) && (
                <CurrencySelect
                  label='customer_currency'
                  placeholder='customer_currency'
                  name='customerCurrency'
                  isRequired
                  control={control}
                  rules={{ required: true }}
                  error={errors['customerCurrency']}
                />
              )}
              {customerType === CustomerType.INSTRUCTING_INSTITUTION && (
                <TextInput
                  label='customer_url'
                  placeholder='customer_url'
                  isRequired
                  formRegister={register('customerUrl', { required: true })}
                  error={errors['customerUrl']}
                />
              )}
              {customerType === CustomerType.ORDERING_CUSTOMER && (
                <>
                  <TextInput
                    label='customer_identifier'
                    placeholder='customer_identifier'
                    isRequired
                    formRegister={register('customerIdentifier', { required: true })}
                    error={errors['customerIdentifier']}
                  />
                  <TextInput
                    label='account_number'
                    placeholder='account_number'
                    isRequired
                    formRegister={register('accountNumber', { required: true })}
                    error={errors['accountNumber']}
                  />
                  <TextInput
                    label='bank_aba'
                    placeholder='bank_aba'
                    isRequired
                    formRegister={register('bankABA', { required: true })}
                    error={errors['bankABA']}
                  />
                  <TextInput
                    label='bank_type'
                    placeholder='bank_type'
                    isRequired
                    formRegister={register('bankType', { required: true })}
                    error={errors['bankType']}
                  />
                </>
              )}
              {[CustomerType.ORDERING_CUSTOMER, CustomerType.ORDERING_INSTITUTION].includes(
                customerType,
              ) && (
                <div className='flex flex-col gap-3'>
                  <label htmlFor='doddFrank'>
                    <Translate value='dodd_frank' /> <span className='text-error-1'>*</span>
                  </label>
                  <Controller
                    name='doddFrank'
                    control={control}
                    render={({ field }) => (
                      <InputSwitch
                        checked={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />{' '}
                </div>
              )}
            </div>
            <div className='flex items-center justify-center mt-10 gap-5'>
              <Button
                type='submit'
                severity='info'
                onClick={() => {
                  handleStepperValidation(ClientForms.CustomerDetails, isValid);
                  if (!isValid) {
                    console.log(errors);
                    showErrorToast();
                    return;
                  }
                }}
              >
                <Translate value='save_and_proceed' />
              </Button>
            </div>
          </form>
        ) : (
          <div className='flex justify-between mt-4'>
            <Button
              severity='contrast'
              className='!px-14'
              onClick={() => {
                jumpToEdit(ClientForms.CustomerDetails);
              }}
            >
              <Translate value={visited?.has(ClientForms.CustomerDetails) ? 'edit' : 'start'} />
            </Button>
            <Progress value={progress} currentStep={1} stepLimit={3} />
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerDetails;