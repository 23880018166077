import { AddOrEdit } from '../../types';
import AddClient from './AddClient';
import Dashboard from './Dashboard';

const clientRoutes = [
  {
    id: 'dashboard',
    index: true,
    handle: {
      breadcrumb: '',
    },

    element: <Dashboard />,
  },
  {
    id: 'childDashboard',
    path: ':customerId/view',
    handle: {
      breadcrumb: '',
    },
    element: <Dashboard />,
  },
  {
    id: 'addnew',
    path: 'add',
    handle: {
      breadcrumb: 'Add New Client',
    },
    element: <AddClient mode={AddOrEdit.Add} />,
  },
  {
    id: 'addChildClient',
    path: ':customerId/add',
    handle: {
      breadcrumb: 'Add New Child Client',
    },
    element: <AddClient mode={AddOrEdit.Add} />,
  },
  {
    id: 'edit',
    path: 'edit/:customerId',
    handle: {
      breadcrumb: 'Edit Client',
    },
    element: <AddClient mode={AddOrEdit.Edit} />,
  },
];

export default clientRoutes;
