import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ArrowDown, Help, UserFollow, UserMultiple } from '@carbon/icons-react';
import { Translate } from '../../../../i18n/translate';
import { MoreAction } from '../../../../icons';
import { InheritedMenu } from '../../../../components';
import { Menu } from 'primereact/menu';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import UserPermissionModal from '../../../../components/UserPermissionModal';
import { setSelectedRole } from '../../../../store/slices/role/roleSlice';

interface RolesTableProps {
  title: string;
  description: string;
  data: Role[];
}

const RolesTable: React.FC<RolesTableProps> = ({ title, description, data }) => {
  const dispatch = useDispatch();
  const [isUserPermissionModalVisible, setUserPermissionModalVisible] = useState(false);

  const menu = useRef<Menu>(null);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setUserPermissionModalVisible(true);
  };

  const handleCloseModal = () => {
    setUserPermissionModalVisible(false);
  };

  const statusBodyTemplate = () => {
    const statusClass = 'bg-success-bg text-success-content';
    const statusText = 'Active';

    return <div className={`w-20 px-3 py-1 rounded-full ${statusClass}`}>{statusText}</div>;
  };

  const actions: MenuItem[] = [
    {
      label: 'viewUsers',
      icon: <UserMultiple />,
      onClick: () => {
        navigate('users');
      },
    },
    {
      label: 'assignUsers',
      icon: <UserFollow />,
      onClick: () => {
        handleOpenModal();
      },
    },
  ];

  const actionBodyTemplate = (role: Role) => {
    return (
      <div>
        <div
          onClick={(event) => {
            console.log(role, event);
            dispatch(setSelectedRole(role));
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={actions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  return (
    <div className='mt-5 mb-5 card'>
      <div className='text-xs-bold text-neutral-1 mb-2'>
        <Translate value={title} />
      </div>
      <div className='text-neutral-3 text-md-regular mb-5'>
        <Translate value={description} />
      </div>

      <div className='inline-block space-x-2 p-3 rounded-full bg-primary-surface-lighter mb-5'>
        <span className='text-primary font-bold'>
          <Translate value='totalRoles' />:
        </span>
        <span className='text-primary font-bold'>{data?.length}</span>
      </div>

      <DataTable value={data} className='p-datatable-gridlines'>
        <Column
          field='roleName'
          header={
            <span className='text-xs-medium'>
              <Translate value='role' />
              <Help className='ml-1 inline text-neutral-4' />
            </span>
          }
        ></Column>
        {/* <Column
          field='usersAssigned'
          header={
            <span className='text-xs-medium'>
              <Translate value='usersAssigned' />
            </span>
          }
          headerStyle={{ borderTopWidth: 0 }}
          body={() => 'Marko Marky + 6 more'} // Static content
        ></Column> */}
        <Column
          field='dateAssigned'
          header={
            <span className='text-xs-medium'>
              <Translate value='dateAssigned' />
              <Help className='ml-1 inline text-neutral-4' />
            </span>
          }
          headerStyle={{ borderTopWidth: 0 }}
          body={() => '11/01/2024'} // Static content
        ></Column>
        <Column
          field='status'
          header={
            <span className='text-xs-medium'>
              <Translate value='status' />
              <ArrowDown className='ml-1 inline text-neutral-4' />
            </span>
          }
          headerStyle={{ borderTopWidth: 0 }}
          body={statusBodyTemplate}
        ></Column>
        <Column header='Actions' body={actionBodyTemplate}></Column>
      </DataTable>
      {isUserPermissionModalVisible && (
        <UserPermissionModal
          visible={isUserPermissionModalVisible}
          onHide={handleCloseModal}
          title='Assign users to Custom role'
        />
      )}
    </div>
  );
};

export default RolesTable;
