import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useState, useEffect } from 'react';
import { Translate } from '../../i18n/translate';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { MultiSelect } from 'primereact/multiselect'; // Import the MultiSelect component
import { Chip } from 'primereact/chip'; // Import the Chip component for selected items
import './index.css';
import { CloseOutline } from '@carbon/icons-react';
import { Badge } from 'primereact/badge';

type FilterField = {
  name: string;
  placeholder: string;
  value: string[];
  options: { label: string; value: string }[];
};

type FilterGroup = {
  header: string;
  fields: FilterField[];
};

interface FilterModalProps {
  visible: boolean;
  title: string;
  setFilterModalToggle: (visible: boolean) => void;
  filters: FilterGroup[];
  onApply: (filters: { [key: string]: string[] }) => void;
}

const FilterModal = ({
  visible,
  title,
  setFilterModalToggle,
  filters,
  onApply,
}: FilterModalProps) => {
  const [formdata, setFormData] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    const initialData: { [key: string]: string[] } = {};
    filters.forEach((group) => {
      group.fields.forEach((field) => {
        initialData[field.name] = field.value || [];
      });
    });
    setFormData(initialData);
  }, [filters]);

  const handleMultiSelectChange = (e: { value: string[] }, fieldName: string) => {
    setFormData({ ...formdata, [fieldName]: e.value });
  };

  const onReset = () => {
    const resetData = Object.keys(formdata).reduce((acc, key) => ({ ...acc, [key]: [] }), {});
    setFormData(resetData);
  };

  const selectedItemTemplate = (label: string, type: string) => {
    if (!label) return null;
    if (type == 'currency')
      return (
        <div className='w-max flex items-center gap-[2px] bg-neutral-surface-dark pb-[1px] pt-[1px] pr-[8px] pl-[3px] h-[30px]  rounded-[16px]'>
          <img
            className='rounded-full w-[19px] h-[19px] object-cover inline-block'
            src={`/images/c_flags_svg/${label?.toLowerCase()}.svg`}
            alt={label || `${label} flag`}
          />
          <span className='text-xs-medium text-neutral-1 ml-1'>{label}</span>
          <CloseOutline onClick={() => removeItem(label)} />
        </div>
      );
    return <Chip label={label} className='mr-2' removable onRemove={() => removeItem(label)} />;
  };

  const itemTemplate = (option: { label: string; value: string }) => {
    return <div>{option.label}</div>;
  };

  const removeItem = (value: string) => {
    const updatedFormdata = { ...formdata };
    Object.keys(updatedFormdata).forEach((key) => {
      updatedFormdata[key] = updatedFormdata[key].filter((item) => item !== value);
    });
    setFormData(updatedFormdata);
  };

  const footerContent = (
    <div className='flex justify-end mt-5 gap-2'>
      <Button severity='contrast' onClick={() => setFilterModalToggle(false)}>
        <Translate value='cancel' />
      </Button>
      <Button severity='info' onClick={onReset}>
        <Translate value='reset' />
      </Button>
      <Button severity='info' onClick={() => onApply(formdata)}>
        <Translate value='apply' />
      </Button>
    </div>
  );

  return (
    <Dialog
      className='w-[30%] scale max-h-[100%] transform scale-100 m-0 w-[50vw] !rounded-none full-screen-dialog'
      header={
        <div className='text-lg font-semibold text-neutral-1'>
          <Translate value={title} />
        </div>
      }
      visible={visible}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      onHide={() => setFilterModalToggle(false)}
      footer={footerContent}
    >
      <Accordion multiple activeIndex={[0]}>
        {filters.map((group, index) => (
          <AccordionTab
            key={index}
            header={
              <div className='flex items-center gap-2'>
                {group.header}
                {formdata[group.fields[0].name] && formdata[group.fields[0].name].length > 0 && (
                  <Badge value={formdata[group.fields[0].name].length}></Badge>
                )}
              </div>
            }
            className='w-[500px]'
          >
            <div className='grid'>
              {group.fields.map((field) => (
                <MultiSelect
                  key={field.name}
                  value={formdata[field.name] || []}
                  options={field.options}
                  onChange={(e) => handleMultiSelectChange(e, field.name)}
                  placeholder={field.placeholder}
                  className='p-2 border rounded-md'
                  itemTemplate={itemTemplate}
                  selectedItemTemplate={(e: string) => selectedItemTemplate(e, field.name)}
                  display='chip'
                />
              ))}
            </div>
          </AccordionTab>
        ))}
      </Accordion>
    </Dialog>
  );
};

export default FilterModal;
