import React, { useState } from 'react';
import CustomTable from './CustomUserTable';
import NoCustomUsers from './NoCustomUser';

const CustomRoles = () => {
  const [rolesData] = useState([
    {
      role: 'Custom',
      usersAssigned: 'Marko Marky + 6 more',
      dateAssigned: '11/01/2024',
      status: 'Active',
    },
    {
      role: 'Custom role',
      usersAssigned: 'Marko Marky + 6 more',
      dateAssigned: '11/01/2024',
      status: 'Active',
    },
    {
      role: 'Test custom role',
      usersAssigned: 'Marko Marky + 6 more',
      dateAssigned: '11/01/2024',
      status: 'Inactive',
    },
  ]);

  return <>{rolesData.length > 0 ? <CustomTable rolesData={rolesData} /> : <NoCustomUsers />}</>;
};

export default CustomRoles;
