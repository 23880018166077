import { Notification, Sun, User } from '@carbon/icons-react';
import { BreadCrumb } from '..';

export default function Header() {
  return (
    <div className='fixed bg-white z-[1100] w-[inherit]'>
      <div className='flex justify-between items-center h-[78px] p-2.5 border-b border-neutral-border'>
        <BreadCrumb />
        <div className='flex items-center mr-3.5'>
          <Sun className='fill-primary ml-[24px]' size={24} />
          <Notification className='fill-primary ml-[24px]' size={24} />
          <User className='fill-primary ml-[24px]' size={24} />
        </div>
      </div>
    </div>
  );
}
