import { MarginType } from '../../../../types';

export const MARGIN_TYPE_OPTIONS = [
  { label: 'Base', value: MarginType.BASE },
  { label: 'Tired', value: MarginType.TIRED },
  { label: 'Group', value: MarginType.GROUP },
  { label: 'Currency', value: MarginType.CURRENCY },
];

export const FEES_TYPE_OPTIONS = [
  { label: 'FX Based', value: 'FX_BASED' },
  { label: 'Same to Same', value: 'SAME_TO_SAME' },
  { label: 'Currency Specific', value: 'CURRENCY_SPECIFIC' },
];
