import apiClient from '../../../utils/apiClient';
import { handleApiResponse } from '../../../utils/helper';

const ROOT = '/api/pricing-management/customers';
const MARGINS_ROOT = '/api/pricing-management/margins';
const FEES_ROOT = '/api/pricing-management/fees';

const ManagePricingService = {
  async createMargin(customerId: number, marginData: any): Promise<Margin | null> {
    try {
      const response = await apiClient.put(`${ROOT}/${customerId}/margin`, marginData);
      return handleApiResponse<Margin | null>(response);
    } catch (error) {
      console.error('Error creating margin:', error);
      return null;
    }
  },

  async createFee(customerId: number, feeData: any): Promise<Fees | null> {
    try {
      const response = await apiClient.put(`${ROOT}/${customerId}/fee`, feeData);
      return handleApiResponse<Fees | null>(response);
    } catch (error) {
      console.error('Error creating fee:', error);
      return null;
    }
  },

  async getMarginById(marginId: string): Promise<Margin | null> {
    try {
      const response = await apiClient.get(`${MARGINS_ROOT}/${marginId}`);
      return handleApiResponse<Margin | null>(response);
    } catch (error) {
      console.error('Error fetching margin by id:', error);
      return null;
    }
  },

  async updateMargin(marginId: number, marginData: any): Promise<Margin | null> {
    try {
      const response = await apiClient.post(`${MARGINS_ROOT}/${marginId}`, marginData);
      return handleApiResponse<Margin | null>(response);
    } catch (error) {
      console.error('Error updating margin:', error);
      return null;
    }
  },

  async deleteMargin(marginId: number): Promise<void> {
    try {
      await apiClient.delete(`${MARGINS_ROOT}/${marginId}`);
    } catch (error) {
      console.error('Error deleting margin:', error);
    }
  },

  async getFeeById(feeId: string): Promise<Fees | null> {
    try {
      const response = await apiClient.get(`${FEES_ROOT}/${feeId}`);
      return handleApiResponse<Fees | null>(response);
    } catch (error) {
      console.error('Error fetching fee by id:', error);
      return null;
    }
  },

  async updateFee(feeId: number, feeData: any): Promise<Fees | null> {
    try {
      const response = await apiClient.post(`${FEES_ROOT}/${feeId}`, feeData);
      return handleApiResponse<Fees | null>(response);
    } catch (error) {
      console.error('Error updating fee:', error);
      return null;
    }
  },

  async deleteFee(feeId: number): Promise<void> {
    try {
      await apiClient.delete(`${FEES_ROOT}/${feeId}`);
    } catch (error) {
      console.error('Error deleting fee:', error);
    }
  },

  async getCustomerMargins(customerId: number): Promise<Margin[] | null> {
    try {
      const response = await apiClient.get(`${ROOT}/${customerId}/margins`);
      return handleApiResponse<Margin[] | null>(response);
    } catch (error) {
      console.error('Error fetching margins for customer:', error);
      return null;
    }
  },

  async getCustomerFees(customerId: number): Promise<Fees[] | null> {
    try {
      const response = await apiClient.get(`${ROOT}/${customerId}/fees`);
      return handleApiResponse<Fees[] | null>(response);
    } catch (error) {
      console.error('Error fetching fees for customer:', error);
      return null;
    }
  },
};

export default ManagePricingService;
