import { useParams } from 'react-router';
import { CustomTabView } from '../../../../components';
import RolesAssignedContent from './RolesAssigned';
import Settings from './Settings';
import StructuresAssigned from './StructuresAssigned.tsx';
import UserDetails from './UserDetails';
import { AddOrEdit } from '../../../../types';

const AddUser = ({ mode }: { mode: AddOrEdit }) => {
  const { userId } = useParams<{ userId?: string }>();
  const isEdit = !!userId;
  // const formValues = useWatch({ control });


  const tabs = [
    {
      header: 'user_details.title',
      content: <UserDetails mode={mode}/>,
    },
    {
      header: 'add_user.roles_assigned',
      content: (
        <RolesAssignedContent
          isEdit={isEdit}
        />
      ),
    },
    { header: 'add_user.structures_assinged', content: <StructuresAssigned /> },
    { header: 'settings', content: <Settings /> },
  ];

  return <CustomTabView tabs={tabs} />;
};

export default AddUser;
