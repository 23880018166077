import CurrencyOutlet from './Currency';
import currencyRoutes from './Currency/routes';
import DisclaimerOutlet from './Disclaimer';
import ManagedPricingOutlet from './ManagePricing';
import managedPricingRoutes from './ManagePricing/routes';
import disclaimerRoutes from './Disclaimer/routes';
import emailSettingsRoutes from './EmailSettings/routes';
import EmailSettingsOutlet from './EmailSettings';

const configurationRoutes = [
  {
    id: 'currency',
    path: 'currency',
    handle: {
      breadcrumb: 'Currency',
    },
    element: <CurrencyOutlet />,
    children: currencyRoutes,
  },
  {
    id: 'managed-pricing',
    path: 'managed-pricing',
    handle: {
      breadcrumb: 'Managed Pricing',
    },
    element: <ManagedPricingOutlet />,
    children: managedPricingRoutes,
  },
  {
    id: 'disclaimer',
    path: 'disclaimer',
    handle: {
      breadcrumb: 'Disclaimer',
    },
    element: <DisclaimerOutlet />,
    children: disclaimerRoutes,
  },
  {
    id: 'emailSettings',
    path: 'email-settings',
    handle: {
      breadcrumb: 'Email Settings',
    },
    element: <EmailSettingsOutlet />,
    children: emailSettingsRoutes,
  },
];

export default configurationRoutes;
