import React, { useEffect, useState } from 'react';
import { Translate } from '../../../../../i18n/translate';
import { DropdownInput, Progress, TextInput } from '../../../../../components';
import { CheckMark } from '../../../../../icons';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { EMAIL_VALIDATION_REGEX, STATUS_OPTIONS } from '../../../../Client/clientConstants';
import CountrySelect from '../../../../../components/CountrySelect';
import { useParams } from 'react-router';
import UserService from '../../../userService';
import { AddOrEdit } from '../../../../../types';

const UserDetails = ({ mode }: { mode: AddOrEdit }) => {
  const { userId } = useParams<{ userId?: string }>();
  // const isEdit = !!userId;
  const [owningCustomerId, setOwningCustomerId] = useState<number>(1);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    // getValues,
    formState: { errors },
  } = useForm<UserFormData>({
    mode: 'onChange',
  });

  const addUserDetails = (data: UserFormData) => {
    const submitData: SubmitUserData = {
      address: {
        street1: data.street1,
        street2: data.street2,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country?.code ?? '',
      },
      userIdentity: {
        firstName: data.firstName,
        lastName: data.lastName,
        mobilePhone: data.phone,
        email: data.emailId,
        // rootCustomerId: owningCustomerId,
        status: data.status,
        // oktaRef: 'test',
        // groupId: '00gj3ub0h2Ab2YZv75d7',
      },
      owningCustomerId: owningCustomerId,
      status: data.status,
    };

    // Ensuring type safety in the Promise resolution
    UserService.createUser([submitData])
      .then((responseData: User | never[]) => {
        console.log(responseData, 'success');
      })
      .catch((error) => {
        console.error('Error creating user:', error);
      });
  };

  useEffect(() => {
    if (mode === AddOrEdit.Edit && userId) {
      setOwningCustomerId(parseInt(userId));
      console.log(userId);
      UserService.getUserById(userId).then((data: User | null) => {
        if (data) {
          console.log(data);
          setValue('emailId', data.userIdentity.email);
          setValue('firstName', data.userIdentity.firstName);
          setValue('lastName', data.userIdentity.lastName);
          if (data?.address) {
            setValue('street1', data.address.street1);
            setValue('street2', data.address.street2);
            setValue('city', data.address.city);
            setValue('state', data.address.state);
            setValue('state', data.address.country);
            setValue('postalCode', data.address.postalCode);
          }

          setValue('phone', data.userIdentity.mobilePhone);
          setValue('status', data.status);
          setValue('phone', data.userIdentity.mobilePhone);
        }
      });
    }
  }, [userId]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(addUserDetails)}>
      <div className='!p-8 card'>
        <div className='grid grid-cols-2'>
          <div>
            <div className='text-sm-bold text-secondary-text'>
              <Translate value='add_user.user_details.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='add_user.user_details.description' />
            </div>
          </div>
          <div className='flex justify-end items-center'>
            <Progress value={50} currentStep={1} stepLimit={3} />
            <CheckMark className='ml-4' />
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4 mt-4 mb-4'>
          <TextInput
            formRegister={register('emailId', {
              required: true,
              pattern: {
                value: EMAIL_VALIDATION_REGEX,
                message: 'invalid_email_address',
              },
            })}
            error={errors.emailId}
            label='email_id'
            placeholder='email_id'
            isRequired
          />
          <TextInput
            formRegister={register('firstName', { required: true })}
            error={errors.firstName}
            label='first_name'
            placeholder='test_name'
            isRequired
          />
          <TextInput
            formRegister={register('lastName')}
            error={errors.lastName}
            label='last_name'
            placeholder='last_name'
          />
          <TextInput
            formRegister={register('street1', { required: true })}
            error={errors.street1}
            label='address_line_1'
            placeholder='address_line_1'
            isRequired
          />
          <TextInput
            formRegister={register('street2')}
            error={errors.street2}
            label='address_line_2'
            placeholder='address_line_2'
          />
          <TextInput
            formRegister={register('phone', { required: true })}
            error={errors.phone}
            id='phone'
            label='phone'
            placeholder='phone_placeholder'
            isRequired
          />
          <DropdownInput
            name='status'
            label='status'
            placeholder='status'
            control={control}
            options={STATUS_OPTIONS}
          />
          <TextInput
            formRegister={register('city')}
            error={errors.city}
            label='city'
            placeholder='city'
          />
          <TextInput
            formRegister={register('state')}
            error={errors.state}
            label='state'
            placeholder='state'
          />
          <CountrySelect
            label='country'
            placeholder='country'
            name='country'
            isRequired
            control={control}
            rules={{ required: true }}
            error={errors['country']}
          />
          <TextInput
            label='zip_code'
            placeholder='zip_code'
            isRequired
            formRegister={register('postalCode', { required: true })}
            error={errors['postalCode']}
          />
        </div>
      </div>
      <div className='flex items-center justify-center mt-10 gap-5'>
        <Button severity='contrast' className='!px-7'>
          <Translate value='cancel' />
        </Button>
        <Button type='submit' severity='info' className='!px-7'>
          <Translate value='save_and_proceed' />
        </Button>
      </div>
    </form>
  );
};

export default UserDetails;
