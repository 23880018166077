export enum CustomerType {
  INSTRUCTING_INSTITUTION = 'INSTRUCTING_INSTITUTION',
  ORDERING_INSTITUTION = 'ORDERING_INSTITUTION',
  ORDERING_CUSTOMER = 'ORDERING_CUSTOMER',
}

export enum KycStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum Status {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  DEFAULT = '',
}

export enum SettlementMethod {
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  SWEEP = 'SWEEP',
  WIRE = 'WIRE',
}

export enum DisclosureType {
  PREPAYMENT_DISCLOSURE = 'DODD_FRANK_PRE_PAYMENT_DISCLOSURE',
  RECEIPT_DISCLOSURE = 'DODD_FRANK_RECEIPT_DISCLOSURE',
}

export enum AddOrEdit {
  Edit,
  Add,
}

export enum ClientForms {
  CustomerDetails = 'CustomerDetails',
  UserDetails = 'UserDetails',
  SiteDetails = 'SiteDetails',
}

export enum Disclaimers {
  LICENSING = 'LICENSING',
  DISCLAIMER = 'DISCLAIMER',
  DODD_FRANK_RECEIPT_DISCLOSURE = 'DODD_FRANK_RECEIPT_DISCLOSURE',
  DODD_FRANK_PRE_PAYMENT_DISCLOSURE = 'DODD_FRANK_PRE_PAYMENT_DISCLOSURE',
}

export enum MarginType {
  BASE = 'BASE',
  TIRED = 'TIERED',
  GROUP = 'GROUP',
  CURRENCY = 'CURRENCY',
}

export enum FeesType {
  FX_BASED = 'FX_BASED',
  SAME_TO_SAME = 'SAME_TO_SAME',
  CURRENCY_SPECIFIC = 'CURRENCY_SPECIFIC',
}

export enum SsoProvider {
  ADMIN = 'ADMIN',
}