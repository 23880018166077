import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import InfoTemplate from '../../../../components/InfoTemplate';
import { Translate } from '../../../../i18n/translate';
import { PageHeadContext } from '../../../../providers/PageHead';
import { Status } from '../../../../types';
import UserService from '../../userService';
import Table from './Table';

const Dashboard = () => {
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [users, setUsers] = useState<User[]>([]);
  const { customerId } = useParams<{ customerId: string }>();
  const [isLoading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const parsedCustomerId = customerId ?? '1';
    const userData = await UserService.getCustomerUser(parsedCustomerId);
    if (userData) {
      const { content: users } = userData;
      const filterNotdeletedUsers = users.filter((user) => user.status !== Status.DELETED);
      setUsers(filterNotdeletedUsers);
      setLoading(false);
    } 
  };

  useEffect(() => {
    setPageHeadData({
      title: 'user.list.title',
      description: 'user.list.description',
      className: 'pb-[16px] pr-[60px]',
      createUI:
        users.length != 0 ? (
          <InfoTemplate
            titleKey='user.addnew.title'
            descriptionKey='user.addnew.description'
            buttonKey='user.addnew.button'
            btnAction={() =>
              navigate(
                `/manage/user/${customerId}/add?sb=${searchParams.get('sb')}&name=${searchParams.get('name')}`,
              )
            }
          />
        ) : (
          <></>
        ),
    });
  }, [users]);

  return (
    <>
      <div className='mt-[28px]'>
        {!isLoading && users.length == 0 && (
          <div className='card'>
            <div className='text-center w-[54%] m-auto'>
              <img src='/images/study--skip.svg' className='m-auto pt-[60px]' />
              <div className='text-md-bold neutral-1 mt-5'>
                <Translate value='user.nousers.title' />
              </div>
              <div className='text-md-regular text-neutral-3 mt-3'>
                <Translate value='user.nousers.description' />
              </div>
            </div>
            <div className='mt-[30px]'>
              <AddNewUserHead />
            </div>
          </div>
        )}
      </div>

      <div className='mt-[50px]'>
        <Table />
      </div>
    </>
  );
};

const AddNewUserHead = () => {
  const navigate = useNavigate();
  return (
    <InfoTemplate
      titleKey='user.addnew.title'
      descriptionKey='user.addnew.description'
      buttonKey='user.addnew.button'
      btnAction={() => navigate('add')}
    />
  );
};

export default Dashboard;
