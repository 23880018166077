import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import UserprincipalService from '../../modules/UserPrinciple/userPrinicple';
import { useAppDispatch } from '../../store';
import { selectCurrentCustomer, setUserPrinciple } from '../../store/slices/main/mainSlice';
import { fetchCurrency } from '../../store/slices/refdata/actions';
import { injectCustomerID } from '../../utils/apiClient';

const { REACT_APP_OKTA_BASE_URL, REACT_APP_OKTA_CLIENT_ID, REACT_APP_OKTA_RETURN_URL } =
  process.env;

const config = {
  issuer: REACT_APP_OKTA_BASE_URL ?? '',
  clientId: REACT_APP_OKTA_CLIENT_ID,
  redirectUri: REACT_APP_OKTA_RETURN_URL,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
};

const oktaAuth = new OktaAuth(config);
export default oktaAuth;

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useAppDispatch();

  const getCusomters = async () => {
    const response = await UserprincipalService.getPricnipleCustomers();
    if (response) dispatch(setUserPrinciple(response));
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      getCusomters();
    }
  }, [authState]);

  useEffect(() => {
    const customerId = selectedCustomer?.id;
    if (!customerId) {
      return;
    }
    if (selectedCustomer) {
      injectCustomerID(selectedCustomer?.id ?? 0);
      dispatch(fetchCurrency(customerId));
    }
  }, [selectedCustomer]);

  if (process.env.NODE_ENV === 'development') {
    return <>{children}</>;
  }

  if (!authState) {
    // Still loading auth state
    return <div>Loading...</div>;
  }

  if (!authState.isAuthenticated) {
    // If user is not authenticated, redirect to login
    oktaAuth.signInWithRedirect();
    return <></>; // Return null while redirecting
  }
  // If user is authenticated, render the children components
  return <>{children}</>;
};


