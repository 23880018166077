import { Security } from '@okta/okta-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import oktaAuth from './auth/Okta';
import routes from './routes';
import { useAppDispatch } from './store';
import { selectCurrentCustomer } from './store/slices/main/mainSlice';
import { fetchCurrency } from './store/slices/refdata/actions';

function App() {
  const selectedCustomer = useSelector(selectCurrentCustomer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const customerId = selectedCustomer?.id;
    if (!customerId) {
      return;
    }
    if (selectedCustomer) {
      dispatch(fetchCurrency(customerId));
    }
  }, [selectedCustomer]);

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={() => {
        window.location.href = '/';
      }}
    >
      <RouterProvider router={routes} />
    </Security>
  );
}

export default App;
