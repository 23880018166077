import { createAsyncThunk } from '@reduxjs/toolkit';
import CurrencyService from '../../../modules/Configuration/Currency/Dashboard/currencyService';

// Define the async action to fetch currency data
export const fetchCurrency = createAsyncThunk<CurrencyConfiguration[], number>(
  'refData/fetchCurrency',
  async (customerId) => {
    const response = await CurrencyService.getCurrencies(customerId);
    return response;
  },
);
