/* eslint-disable @typescript-eslint/restrict-template-expressions */

const statuses: { [key: string]: StatusColor } = {
  PENDING: {
    name: 'Pending',
    backgroundColor: 'bg-sucess-bg',
    textColor: 'text-success-content',
  },
  completed: {
    name: 'Completed',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  ACTIVE: {
    name: 'Active',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  INACTIVE: {
    name: 'In Active',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  inProgress: {
    name: 'In Progress',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
  failed: {
    name: 'Failed',
    backgroundColor: 'bg-success-bg',
    textColor: 'text-success-content',
  },
};

const Status = ({ status }: { status: string }) => {
  return (
    statuses[status] && (
      <>
        <div
          className={`rounded-[16px] ${statuses[status].backgroundColor} ${statuses[status].textColor} py-[2px] px-[8px] w-max`}
        >
          {statuses[status].name}
        </div>
      </>
    )
  );
};

export default Status;
