import { Button } from 'primereact/button';
import { Progress, TextInput } from '../../../../components';
import TextArea from '../../../../components/TextArea';
import { Translate } from '../../../../i18n/translate';
import Checkmark from '../../../../icons/Checkmark';
import { useState } from 'react';
import PermissionModal from '../../../../components/PermissionModal';
import InfoTemplate from '../../../../components/InfoTemplate';

const NoCustomUsers = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <div className='card'>
        <div className='flex justify-between m-auto'>
          <div>
            <div className='text-xs-bold text-neutral-1'>
              <Translate value='roles.custom.title' />
            </div>
            <div className='text-neutral-3 text-md-regular'>
              <Translate value='roles.custom.description' />
            </div>
          </div>
          <div className='flex items-center'>
            <Progress value={25} currentStep={1} stepLimit={4} />
            <Checkmark type='success' className='ml-4' />
          </div>
        </div>
        <div className='mt-9 ml-14 grid grid-cols-3'>
          <TextInput id='roleName' label='role_name' placeholder='test_role_name' isRequired />
        </div>
        <div className='mt-9 ml-14'>
          <TextArea
            id='roleDescription'
            label='test_role_desc_label'
            placeholder='test_role_description'
            label2='write_details'
            isRequired
          />
        </div>
      </div>
      <div className='mt-5'>
        <InfoTemplate
          titleKey='select_primary_title'
          descriptionKey='select_primary_desc'
          buttonKey='select_preset_button'
          btnAction={() => console.log('Select')}
        />
      </div>
      <div className='flex items-center justify-center mt-10 gap-5'>
        <Button severity='contrast' className='!px-28'>
          <Translate value='button_cancel' />
        </Button>
        <Button severity='info' className='!px-7' onClick={handleOpenModal}>
          <Translate value='button_proceed_permission' />
        </Button>
      </div>
      <PermissionModal visible={isModalVisible} onHide={handleCloseModal} permissions={[]} />
    </>
  );
};

export default NoCustomUsers;
