import { Button } from 'primereact/button';
import { Translate } from '../../../../i18n/translate';
import { RichEditor, TextInput } from '../../../../components';
import './index.css';

const Dashboard = () => {
  return (
    <div className='mx-8 px-6 py-2 bg-white rounded-c8 border border-neutral-surface-dark'>
      <div className='flex justify-between py-4 border-b border-neutral-border-2'>
        <div>
          <div className='text-lg-semibold'>
            <Translate value='email_server_details' />
          </div>
          <div className='text-neutral-3 mt-1'>
            <Translate value='subheading_placeholder' />
          </div>
        </div>
        <div>
          <Button severity='info'>
            <Translate value='save' />
          </Button>
        </div>
      </div>
      <div className='py-6 border-b border-neutral-border-2'>
        <div className='w-3/5 flex gap-2 justify-between flex-col lg:flex-row'>
          <Translate value='email_provider' className='email-settings-label' />
          <TextInput value='SMTP Provider' disabled />
        </div>
      </div>
      <div className='py-6 border-b border-neutral-border-2'>
        <div className='w-3/5 flex flex-col gap-12'>
          <div className='email-settings-input'>
            <Translate value='SMTP_server_name' className='email-settings-label' />
            <TextInput placeholder='enter_server_name' />
          </div>
          <div className='email-settings-input'>
            <Translate value='port_number' className='email-settings-label' />
            <TextInput placeholder='enter_port_number' />
          </div>
          <div className='email-settings-input'>
            <Translate value='user_name' className='email-settings-label' />
            <TextInput placeholder='enter_user_name' />
          </div>
          <div className='email-settings-input'>
            <Translate value='password' className='email-settings-label' />
            <TextInput placeholder='enter_password' />
          </div>
          <div className='email-settings-input'>
            <Translate value='SPF_record' className='email-settings-label' />
            <TextInput placeholder='enter_spf_record' />
          </div>
        </div>
      </div>
      <div className='py-6 border-b border-neutral-border-2'>
        <div className='w-3/5 flex justify-between flex-col lg:flex-row'>
          <Translate value='sender_from_address' className='email-settings-label' />
          <TextInput placeholder='enter_from_email' />
        </div>
      </div>
      <div className='py-6'>
        <div className='flex w-[80%] justify-between flex-wrap'>
          <Translate value='email_signature' className='email-settings-label' />
          <div className='w-[36rem]'>
            <RichEditor />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
