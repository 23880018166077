import { Menu } from 'primereact/menu';
import React, { forwardRef } from 'react';
import { Translate } from '../../i18n/translate';
import './Menu.css';

interface MenuProps {
  items: MenuItem[];
  popupAlign?: 'left' | 'right';
}
// eslint-disable-next-line react/display-name
const InheritedMenu = forwardRef<Menu | null, MenuProps>(({ items, popupAlign = 'left' }, ref) => {
  const updatedItems = items.map((item) => ({
    ...item,
    template: (
      <_Template
        label={item.label}
        icon={item.icon}
        className={item.className ?? ''}
        onClick={(event: React.MouseEvent) => {
          if (item.onClick) {
            item.onClick(event);
          }
          if (ref && 'current' in ref && ref.current) {
            ref.current.toggle(event);
          }
        }}
      />
    ),
  }));
  return <Menu model={updatedItems} popup ref={ref} popupAlignment={popupAlign} />;
});

const _Template = ({
  label,
  icon,
  className,
  onClick,
}: {
  label: string;
  icon: React.ReactNode;
  className: string;
  onClick?: (event: React.MouseEvent) => void;
}) => (
  <div className='p-menuitem-content' onClick={onClick}>
    <span className={`p-menuitem-link ${className}`}>
      {icon} <span className={`${icon ? 'ml-2' : ''}`}>{<Translate value={label} />}</span>
    </span>
  </div>
);

export default InheritedMenu;
