import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Translate } from '../../i18n/translate';
// import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import UserPermissionModal from '../UserPermissionModal';
import PermissionCard from './PermissionCard';
import { usePermissionModal } from './permissionModal';
interface PermissionModalProps {
  visible: boolean;
  onHide: () => void;
  permissions: RolePermission[];
  readOnly?: boolean;
}

const PermissionModal = ({
  visible,
  onHide,
  permissions,
  readOnly = true,
}: PermissionModalProps) => {
  const {
    selectedAll,
    selectedHeaders,
    selectedPermissions,
    isUserPermissionModalVisible,
    onSelectAllChange,
    onHeaderChange,
    onPermissionChange,
    onSave,
    handleCloseModal,
  } = usePermissionModal(permissions);

  const headerElement = () => (
    <div className='flex justify-between items-center w-full p-4'>
      <div className='flex flex-col gap-1'>
        <div className='text-lg font-semibold text-neutral-1'>
          <Translate value='permission_modal_header' />
        </div>
        <div className='text-sm font-regular text-neutral-3'>
          <Translate value='permission_modal_desc' />
        </div>
      </div>
    </div>
  );

  const footerContent = (
    <div className='flex justify-center mt-5 gap-2'>
      <Button severity={readOnly ? 'info' : 'contrast'} onClick={onHide}>
        <Translate value='return' />
      </Button>
      {!readOnly && (
        <Button severity='info' onClick={() => onSave(onHide)}>
          <Translate value='assign_permission' />
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Dialog
        className='scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
        header={headerElement}
        visible={visible}
        style={{ width: '75vw', height: '100vh', position: 'fixed', top: 0, right: 0 }}
        onHide={onHide}
        footer={footerContent}
        draggable={false}
      >
        <div className='ml-12 !no-scrollbar'>
          <div className='mb-8 flex items-center'>
            {!readOnly && (
              <>
                <Checkbox onChange={onSelectAllChange} checked={selectedAll}></Checkbox>
                <label htmlFor='selectAll' className='ml-2'>
                  <Translate value='select_permission' />
                </label>
              </>
            )}
          </div>
          <div className='grid grid-cols-3 gap-3 mb-4'>
            {permissions.map((permission, index) => (
              <PermissionCard
                key={index}
                permissionName={permission.feature}
                actions={permission.actions}
                selectedHeaders={selectedHeaders}
                selectedPermissions={selectedPermissions}
                onHeaderChange={onHeaderChange}
                onPermissionChange={onPermissionChange}
                readOnly={readOnly}
              />
            ))}
          </div>
        </div>
      </Dialog>
      {isUserPermissionModalVisible && (
        <UserPermissionModal
          visible={isUserPermissionModalVisible}
          onHide={handleCloseModal}
          title='Assign users to Custom role'
        />
      )}
    </>
  );
};

export default PermissionModal;
