export const enumToCamelCase = (value: string) =>
  value
    .split('_')
    .map((i) => i.charAt(0).toUpperCase() + i.slice(1).toLocaleLowerCase())
    .join(' ');

export function handleApiResponse<T>(response: any, field = ''): T {
  const { data, result } = response.data;
  if (result.success) {
    if (field) {
      return data[field] as T;
    }
    return data as T;
  } else {
    console.error('API request failed:', result.errors);
    throw new Error('API request failed');
  }
}
