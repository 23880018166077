import { Edit, TrashCan, View } from '@carbon/icons-react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Confirm, InheritedMenu, PaginatorTemplate, WarningIcon } from '../../../../components';
import { MoreAction } from '../../../../icons';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { MarginType } from '../../../../types';
import { enumToCamelCase } from '../../../../utils/helper';
import ManagePricingService from '../managePricingService';

const Margins = ({
  refreshData,
  onEdit,
}: {
  refreshData: boolean;
  onEdit: (margin: Margin) => void;
}) => {
  const [margins, setMargins] = useState<Margin[]>([]);
  const currentCustomer = useSelector(selectCurrentCustomer);
  const [currentActionMargin, setCurrentActionMargin] = useState<Margin | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const menu = useRef<Menu>(null);
  useEffect(() => {
    getMargins();
  }, [currentCustomer]);

  useEffect(() => {
    if (refreshData) {
      getMargins();
    }
  }, [refreshData]);

  const getMargins = async () => {
    if (currentCustomer?.id != undefined) {
      const margins = await ManagePricingService.getCustomerMargins(currentCustomer.id);
      if (margins) {
        setMargins(margins);
      } else {
        setMargins([]);
      }
    }
  };

  const items: MenuItem[] = [
    {
      label: 'View',
      icon: <View />,
      onClick: () => {
        if (currentActionMargin) onEdit(currentActionMargin);
      },
    },
    {
      label: 'Edit',
      icon: <Edit />,
      onClick: () => {
        if (currentActionMargin) onEdit(currentActionMargin);
      },
    },
    {
      label: 'delete',
      icon: <TrashCan />,
      onClick: () => {
        setShowDeleteDialog(true);
      },
      className: 'delete-menu-item',
    },
  ];

  const actionBodyTemplate = (margin: Margin) => {
    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActionMargin(margin);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={items} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const deletMargin = async (margin: Margin) => {
    if (margin.id) {
      await ManagePricingService.deleteMargin(margin.id);
    }
    getMargins();
  };

  return (
    <>
      <Confirm
        confirmLabel='Delete'
        headerIcon={<WarningIcon />}
        title={`Delete '${currentActionMargin?.marginType}'?`}
        message='Are you sure you want to delete this margin?'
        confirmButtonSeverify='danger'
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          if (currentActionMargin) {
            deletMargin(currentActionMargin);
          }
          setShowDeleteDialog(false);
        }}
        visible={showDeleteDialog}
      />
      <div className='table-card'>
        <DataTable
          value={margins}
          paginator={true}
          paginatorTemplate={PaginatorTemplate}
          first={0}
          rows={10}
          totalRecords={margins.length}
          header={
            <div>
              <div className='text-neutral-1 text-lg-semibold'>
                Total
                <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
                  {margins.length}
                </span>
              </div>
            </div>
          }
        >
          <Column
            field='marginType'
            header='Margin Type'
            body={(margin: Margin) => <div>{enumToCamelCase(margin.marginType)}</div>}
          />
          <Column field='description' header='Description' />
          <Column
            field='currencyList'
            header='Currency'
            body={(margin: Margin) => <div>{margin.currencyList.join(', ')}</div>}
          />
          <Column
            field='buyCCYMargin'
            header='Buy CCY Margin'
            body={(margin: Margin) => (
              <div>
                {margin.marginType != MarginType.TIRED ? margin.managedMarginList[0].margin : ''}
              </div>
            )}
          />
          <Column
            field='minAmount'
            header='Min Amount'
            body={(margin: Margin) => (
              <div>
                {margin.marginType != MarginType.TIRED
                  ? margin.managedMarginList[0].minThreshold
                  : ''}
              </div>
            )}
          />
          <Column
            field='maxAmount'
            header='Max Amount'
            body={(margin: Margin) => (
              <div>
                {margin.marginType != MarginType.TIRED
                  ? margin.managedMarginList[0].maxThreshold
                  : ''}
              </div>
            )}
          />
          <Column header='Actions' body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default Margins;
