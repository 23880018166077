import { LoginCallback } from '@okta/okta-react';
import { createBrowserRouter } from 'react-router-dom';
import { RequireAuth } from './auth/Okta';
import { ErrorPage } from './components';
import RootLayout from './components/Layout';
import ClientOutlet from './modules/Client';
import clientRoutes from './modules/Client/routes';
import ConfigurationOutlet from './modules/Configuration';
import configurationRoutes from './modules/Configuration/routes';
import ManageOutlet from './modules/Manage';
import manageRoutes from './modules/Manage/routes';

const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <RootLayout />
      </RequireAuth>
    ),

    errorElement: <ErrorPage />,
    children: [
      {
        path: 'client',
        element: <ClientOutlet />,
        handle: {
          breadcrumb: 'Client Management',
        },
        children: clientRoutes,
      },
      {
        path: 'manage',
        handle: {
          breadcrumb: 'Manage',
        },
        element: <ManageOutlet />,
        children: manageRoutes,
      },
      {
        path: 'configuration',
        element: <ConfigurationOutlet />,
        children: configurationRoutes,
      },
    ],
  },
  {
    path: 'login/callback',
    element: <LoginCallback />,
  },
]);

export default routes;
