import { Edit, TrashCan, View } from '@carbon/icons-react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Confirm, InheritedMenu, PaginatorTemplate, WarningIcon } from '../../../../components';
import { MoreAction } from '../../../../icons';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { enumToCamelCase } from '../../../../utils/helper';
import ManagePricingService from '../managePricingService';

const Fees = ({ refreshData, onEdit }: { refreshData: boolean; onEdit: (fees: Fees) => void }) => {
  const [fees, setFees] = useState<Fees[]>([]);
  const currentCustomer = useSelector(selectCurrentCustomer);
  const [currentActionFees, setCurrentActionFees] = useState<Fees | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const menu = useRef<Menu>(null);

  useEffect(() => {
    if (currentCustomer) getFees();
  }, [currentCustomer]);

  useEffect(() => {
    if (refreshData) {
      getFees();
    }
  }, [refreshData]);

  const getFees = async () => {
    if (currentCustomer?.id != undefined) {
      const fees = await ManagePricingService.getCustomerFees(currentCustomer?.id ?? 1);
      if (fees) {
        setFees(fees);
      } else {
        setFees([]);
      }
    }
  };

  const items: MenuItem[] = [
    {
      label: 'View',
      icon: <View />,
      onClick: () => {
        if (currentActionFees) onEdit(currentActionFees);

        console.log();
      },
    },
    {
      label: 'Edit',
      icon: <Edit />,
      onClick: () => {
        if (currentActionFees) onEdit(currentActionFees);
      },
    },
    {
      label: 'delete',
      icon: <TrashCan />,
      onClick: () => {
        setShowDeleteDialog(true);
      },
      className: 'delete-menu-item',
    },
  ];

  const actionBodyTemplate = (fees: Fees) => {
    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActionFees(fees);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={items} ref={menu} popupAlign='left' />
      </div>
    );
  };

  const deleteFees = async (fees: Fees) => {
    if (fees.id) {
      await ManagePricingService.deleteFee(fees.id);
    }
    getFees();
  };

  return (
    <>
      <Confirm
        confirmLabel='Delete'
        headerIcon={<WarningIcon />}
        title={`Delete '${currentActionFees?.feeType}'?`}
        message='Are you sure you want to delete this fee?'
        confirmButtonSeverify='danger'
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          if (currentActionFees) {
            deleteFees(currentActionFees);
          }
          setShowDeleteDialog(false);
        }}
        visible={showDeleteDialog}
      />
      <div className='table-card'>
        <DataTable
          value={fees}
          paginator={true}
          paginatorTemplate={PaginatorTemplate}
          first={0}
          rows={10}
          totalRecords={fees.length}
          header={
            <div>
              <div className='text-neutral-1 text-lg-semibold'>
                Total
                <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
                  {fees.length}
                </span>
              </div>
            </div>
          }
        >
          <Column
            field='feeType'
            header='Fee Type'
            body={(fees: Fees) => <div>{enumToCamelCase(fees.feeType)}</div>}
          />
          <Column field='description' header='Description' />
          <Column field='currency' header='Currency' />
          <Column field='feeCurrency' header='Fee CCY' />
          <Column field='amount' header='Amount' />
          <Column header='' body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default Fees;
