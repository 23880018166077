import RoleOutlet from './Role';
import roleRoutes from './Role/routes';
import UserOutlet from './User';
import userRoutes from './User/routes';

const manageRoutes = [
  {
    id: 'user',
    path: 'user',
    handle: {
      breadcrumb: 'User',
    },
    element: <UserOutlet />,
    children: userRoutes,
  },
  {
    id: 'role',
    path: 'role',
    handle: {
      breadcrumb: 'Role',
    },
    element: <RoleOutlet />,
    children: roleRoutes,
  },
];

export default manageRoutes;
