import Dashboard from './Dashboard';

const currencyRoutes = [
  {
    id: 'currencyDashboard',
    index: true,
    element: <Dashboard />,
  },
];

export default currencyRoutes;
