import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../rootReducer';

export interface MainState {
  selectedRole: Role | null;
}

export const initialState: MainState = {
  selectedRole: null,
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setSelectedRole: (state: MainState, action: PayloadAction<Role>) => {
      state.selectedRole = action.payload;
    },
  },
});

export const { setSelectedRole } = roleSlice.actions;
export const selectSelectedRole = (state: RootState) => state.role.selectedRole;
export default roleSlice.reducer;
