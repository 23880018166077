import { Outlet } from 'react-router';
import { PageHeadProvider } from '../../../providers/PageHead';

const ManagedPricingOutlet = () => {
  return (
    <>
      <PageHeadProvider>
        <Outlet />
      </PageHeadProvider>
    </>
  );
};
export default ManagedPricingOutlet;
