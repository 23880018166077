import { useState } from 'react';
import { Outlet } from 'react-router';
import Header from '../Header';
import InactivityTimeout from '../InactivityTimeout';
import ScrollToTop from '../ScrollToTop';
import Sidebar from '../Sidebar';
import './Layout.css';

function Layout() {
  const [sideBarVisible, setSideBarVisible] = useState(true);
  return (
    <div className='flex max-w-full h-screen bg-background'>
      <div className=' h-full'>
        <Sidebar visible={sideBarVisible} setVisible={setSideBarVisible} />
      </div>
      <div
        className={`overflow-y-auto content ${sideBarVisible ? 'content-shift' : 'content-collapsed'}`}
      >
        <Header />
        <div>
          <div className='h-[78px]'></div>
          <div className='mb-[120px]'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

const RootLayout = () => {
  return (
    <>
      <InactivityTimeout />
      <ScrollToTop />
      <Layout />
    </>
  );
};

export default RootLayout;
