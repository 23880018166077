import { CloseOutline, Redo, TrashCan, ViewFilled } from '@carbon/icons-react';
import { Column } from 'primereact/column';
import { DataTable, DataTablePageEvent } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Confirm,
  InheritedMenu,
  PaginatorTemplate,
  Status,
  WarningIcon,
} from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { MoreAction } from '../../../../icons';
import { Status as StatusEnum } from '../../../../types/enum';
import UserService from '../../userService';

const Table = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectUsers] = useState<User[] | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentActionUser, setCurrentActionUser] = useState<User | null>(null);
  const menu = useRef<Menu>(null);
  const { customerId } = useParams<{ customerId: string }>();
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const navigate = useNavigate();

  const [pageParams, setPageParams] = useState<PageParams>({
    first: 0,
    rows: 10,
    page: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  const deleteUser = async () => {
    if (currentActionUser?.id) {
      await UserService.delete(currentActionUser?.id);
    }
  };

  useEffect(() => {
    getUsers(pageParams);
  }, [pageParams]);

  const getUsers = async (params: PageParams) => {
    setLoading(true);
    const parsedCustomerId = customerId ?? '1';
    try {
      const { page, rows } = params;
      const data = await UserService.getCustomerUser(parsedCustomerId, page, rows);
      if (data) {
        const { content: users, totalElements } = data;
        setUsers(users);
        setTotalRecords(totalElements);
      }
    } finally {
      setLoading(false);
    }
  };

  const onPage = (event: DataTablePageEvent) => {
    setPageParams({
      ...event,
      page: event.page ?? 0,
      rows: event.rows ?? 10,
      first: event.first ?? 0,
    });
  };

  const updateStatus = async (status: StatusEnum) => {
    if (currentActionUser?.id) {
      await UserService.updateStatus(currentActionUser?.id, status);
      getUsers(pageParams);
    }
  };

  const actionBodyTemplate = (user: User) => {
    const actions: MenuItem[] = [
      {
        label: 'deactivateUser',
        onClick: () => {
          updateStatus(StatusEnum.INACTIVE);
        },
        icon: <CloseOutline />,
      },
      {
        label: 'activateUser',
        onClick: () => {
          updateStatus(StatusEnum.ACTIVE);
        },
        icon: <Redo />,
      },
      {
        label: 'viewDetails',
        onClick: () => {
          navigate(`/manage/user/edit/${user?.id}`);
        },
        icon: <ViewFilled />,
      },
      {
        label: 'deleteUser',
        onClick: () => {
          setShowDeleteDialog(true);
        },
        className: 'delete-menu-item',
        icon: <TrashCan />,
      },
    ];

    const actionFilter: MenuItem[] = actions.filter((item) => {
      if (user.status === StatusEnum.ACTIVE && item.label === 'activateUser') {
        return false;
      }
      if (user.status === StatusEnum.INACTIVE && item.label === 'deactivateUser') {
        return false;
      }
      return true;
    });

    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(actionFilter);
            setCurrentActionUser(user);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  return (
    <>
      <Confirm
        headerIcon={<WarningIcon />}
        confirmButtonSeverify='danger'
        title='Are you sure you want to delete this user?'
        message='Deleting this user will delete them from all the roles they are assigned to'
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          setShowDeleteDialog(false);
          deleteUser();
          getUsers(pageParams);
        }}
        visible={showDeleteDialog}
      />
      <div className='table-card'>
        {users.length != 0 && (
          <DataTable
            paginatorTemplate={PaginatorTemplate}
            totalRecords={totalRecords}
            lazy
            paginator
            loading={loading}
            first={pageParams.first}
            rows={pageParams.rows}
            onPage={onPage}
            header={
              <div>
                <div className='text-neutral-1 text-lg-semibold'>
                  Total
                  <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
                    {totalRecords}
                  </span>
                </div>
                <div className='mt-[15px]'>
                  <Translate
                    value='userListTableDescription'
                    className='text-neutral-3 text-sm-regular'
                  />
                </div>
              </div>
            }
            value={users}
            size={'large'}
            selectAll={true}
            selectionMode={'checkbox'}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            selection={selectedUsers!}
            onSelectionChange={(e: { value: User[] }) => setSelectUsers(e.value)}
            dataKey='id'
          >
            <Column selectionMode='multiple' headerStyle={{ width: '1rem' }}></Column>
            <Column
              field=''
              header='Client Name'
              body={(user: User) => (
                <div className='text-sm-medium text-neutral-2'>
                  {user.userIdentity.firstName} {user.userIdentity.lastName}
                  <div className='text-sm-regular text-neutral-3'>{user.userIdentity.email}</div>
                </div>
              )}
            ></Column>
            <Column
              sortable
              field='status'
              header='Status'
              body={(client: Client) => <Status status={client.status} />}
            ></Column>
            <Column field='role' header='Role'></Column>
            <Column header='Actions' body={actionBodyTemplate}></Column>
          </DataTable>
        )}
      </div>
    </>
  );
};

export default Table;
