import { Status } from '../../types';
import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';

const ROOT = '/api/user-management/users';
const ROLE_PATH = 'api/customer-role-management';
const CUSTOMER_ROLES = '/customer-roles/';
const USER_ROLES = '/user-roles/';
const ASSIGN_ROLES = '/users/assign-roles';

const UserService = {
  async getUsers(customerId: string): Promise<User[]> {
    try {
      const response = await apiClient.get(`${ROOT}/${customerId}`);
      return handleApiResponse<User[]>(response);
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  },

  async getCustomerUser(customerId: string, page = 0, rows = 0): Promise<UsersResponse | null> {
    try {
      const response = await apiClient.get(
        `${ROOT}/customers/${customerId}?page=${page}&size=${rows}`,
      );
      return handleApiResponse<UsersResponse>(response);
    } catch (error) {
      console.error('Error fetching users:', error);
      return null;
    }
  },
  async getUserById(id: string): Promise<User | null> {
    try {
      const response = await apiClient.get(`${ROOT}/${id}`);
      return handleApiResponse<User | null>(response);
    } catch (error) {
      console.error(`Error fetching user with id ${id}:`, error);
      return null;
    }
  },

  async updateStatus(id: number, status: Status): Promise<User | null> {
    try {
      const response = await apiClient.put(`${ROOT}/${id}/status?status=${status}`);
      return handleApiResponse<User | null>(response);
    } catch (error) {
      console.error(`Error fetching user with id ${id}:`, error);
      return null;
    }
  },

  async createUser(user: SubmitUserData[]) {
    try {
      const response = await apiClient.post(`${ROOT}`, user);
      return handleApiResponse<User>(response);
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },

  async getRoleByCustomerId(id: number): Promise<any> {
    try {
      const response = await apiClient.get(`${ROLE_PATH}${CUSTOMER_ROLES}${id}`);
      return handleApiResponse<any>(response);
    } catch (error) {
      console.error(`Error fetching user with id ${id}:`, error);
      return null;
    }
  },

  async getRoleByUserId(id: string | undefined): Promise<any> {
    try {
      const response = await apiClient.get(`${ROLE_PATH}${USER_ROLES}${id}`);
      return handleApiResponse<any>(response);
    } catch (error) {
      console.error(`Error fetching user with id ${id}:`, error);
      return null;
    }
  },

  async assignRolesToUser(data: any) {
    try {
      const response = await apiClient.post(`${ROLE_PATH}${ASSIGN_ROLES}`, data);
      return handleApiResponse<User>(response);
    } catch (error) {
      console.error('Error fetching currency:', error);
      return [];
    }
  },

  getById(id: string) {
    return apiClient.get(`${ROOT}/${id}`);
  },

  delete(id: number) {
    return apiClient.delete(`${ROOT}/${id}`);
  },
};

export default UserService;
