import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { CustomStepper } from '../../../components';
import { Translate } from '../../../i18n/translate';
import { PageHeadContext } from '../../../providers/PageHead';
import { AddOrEdit, ClientForms } from '../../../types';
import CustomerDetails from '../CustomerDetails';
import SiteDetails from '../SiteDetails';
import UserDetails from '../UserDetails';

interface Step {
  id: string;
  content: React.ReactNode;
}

const AddClient = ({ mode }: { mode: AddOrEdit }) => {
  const [activeStep, setActiveStep] = useState<string>('');
  const [visited, setVisited] = useState<Set<string>>(new Set());
  const [visitPermitted, setVisitPermitted] = useState<Set<string>>(new Set());
  const [validationOnStepper, setValidationOnStepper] = useState<Set<string>>(new Set());
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [owningClientId, setOwningClientid] = useState<number>(0);

  useEffect(() => {
    if (mode === AddOrEdit.Edit) {
      setVisitPermitted(
        new Set([ClientForms.CustomerDetails, ClientForms.UserDetails, ClientForms.SiteDetails]),
      );
      setVisited(
        new Set([ClientForms.CustomerDetails, ClientForms.UserDetails, ClientForms.SiteDetails]),
      );
      setPageHeadData({
        title: 'editClient.title',
        description: 'editClient.description',
      });
    } else {
      setVisitPermitted(new Set([ClientForms.CustomerDetails, ClientForms.SiteDetails]));
      setPageHeadData({
        title: 'addclient.title',
        description: 'addclient.description',
      });
    }
  }, [setPageHeadData]);

  const jumpToEdit = (step: string) => {
    setActiveStep(step);
  };

  const handleStepperValidation = (formName: ClientForms, isValid: boolean) => {
    setValidationOnStepper((prev: Set<string>) => {
      const newValidations = new Set(prev);
      if (!isValid) {
        newValidations.add(formName);
      } else {
        newValidations.delete(formName);
      }
      return newValidations;
    });
  };

  const steps: Step[] = [
    {
      id: ClientForms.CustomerDetails,
      content: (
        <CustomerDetails
          activeStep={activeStep}
          onSaveAndProceed={() => {
            setVisitPermitted(new Set([...visitPermitted, ClientForms.UserDetails]));
            setVisited(new Set([...visited, ClientForms.CustomerDetails]));
            if (mode === AddOrEdit.Add && !visited.has(ClientForms.UserDetails)) {
              setActiveStep(ClientForms.UserDetails);
            } else {
              setActiveStep('');
            }
          }}
          handleStepperValidation={handleStepperValidation}
          jumpToEdit={jumpToEdit}
          setOwningClientid={setOwningClientid}
          mode={mode}
          visited={visited}
        />
      ),
    },
    {
      id: ClientForms.UserDetails,
      content: (
        <UserDetails
          activeStep={activeStep}
          jumpToEdit={jumpToEdit}
          handleStepperValidation={handleStepperValidation}
          inheritedClientId={owningClientId}
          mode={mode}
          visitPermitted={visitPermitted}
          visited={visited}
          onSaveAndProceed={() => {
            if (mode === AddOrEdit.Add && !visited.has(ClientForms.SiteDetails)) {
              setActiveStep(ClientForms.SiteDetails);
            } else {
              setActiveStep('');
            }
            setVisitPermitted(new Set([...visitPermitted, ClientForms.SiteDetails]));
            setVisited(new Set([...visited, ClientForms.UserDetails]));
          }}
        />
      ),
    },
    {
      id: ClientForms.SiteDetails,
      content: (
        <SiteDetails
          activeStep={activeStep}
          visited={visited}
          mode={mode}
          visitPermitted={visitPermitted}
          jumpToEdit={jumpToEdit}
          onSaveAndProceed={() => {
            setActiveStep('');
          }}
        />
      ),
    },
  ];
  return (
    <>
      <div className='pt-10 mx-auto rounded-md'>
        <CustomStepper
          steps={steps}
          activeStep={activeStep}
          visited={visited}
          validations={validationOnStepper}
        />
        <div className='flex items-center justify-center mt-10 gap-5'>
          <Button severity='contrast' className='!px-7'>
            <Translate value='save_as_draft' />
          </Button>
          <Button severity='info' className='!px-7'>
            <Translate value='continue' />
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddClient;
