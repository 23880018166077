import React, { useState, ReactNode } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import './index.css';
import { Translate } from '../../i18n/translate';

interface Tab {
  header: string;
  content: ReactNode;
}

interface CustomTabViewProps {
  tabs: Tab[];
  initialIndex?: number;
}

const CustomTabView: React.FC<CustomTabViewProps> = ({ tabs, initialIndex = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  return (
    <TabView
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
      className='tab-view'
    >
      {tabs.map((tab, index) => (
        <TabPanel key={index} header={<Translate value={tab.header} />}>
          {tab.content}
        </TabPanel>
      ))}
    </TabView>
  );
};

export default CustomTabView;
