import { PickListChangeEvent } from 'primereact/picklist';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CurrencyPickList from '../../../../components/CurrencyPicklist';
import Select from '../../../../components/Select';
import { translateWithValues } from '../../../../i18n/translate';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import { CURRENCY_ACTIONS } from '../../configurations-constants';
import CurrencyService from './currencyService';

const AvailableCurrencies = () => {
  const [source, setSource] = useState<CurrencyConfiguration[]>([]);
  const [target, setTarget] = useState<CurrencyConfiguration[]>([]);
  const [isSellOption, setIsSellOption] = useState(false);
  const [currencyAction, setCurrencyAction] = useState(CURRENCY_ACTIONS[0].value);
  const selectedCurrencies = useSelector(selectCustomerCurrencies);
  const currentCustomer = useSelector(selectCurrentCustomer);

  const handleOptionChange = (e: { value: string }) => {
    setIsSellOption(e.value === CURRENCY_ACTIONS[1].value);
    setCurrencyAction(e.value);
  };

  const handleChange = (event: PickListChangeEvent) => {
    setSource(event.source as CurrencyConfiguration[]);
    setTarget(event.target as CurrencyConfiguration[]);
    if (!isSellOption) buyCurrency(event.target as CurrencyConfiguration[]);
    else sellCurrency(event.target as CurrencyConfiguration[]);
  };

  const buyCurrency = (targetList: CurrencyConfiguration[]) => {
    const codes: any = targetList.map((item: CurrencyConfiguration) => {
      return { id: '0', code: item.isocode };
    });
    CurrencyService.postBuyCurrencies(currentCustomer?.id || 1, codes)
      .then((data) => console.log(data))
      .catch((error) => {
        console.log(error);
        setTarget([]);
      });
  };

  const sellCurrency = (targetList: CurrencyConfiguration[]) => {
    const codes: any = targetList.map((item: CurrencyConfiguration) => {
      return { id: '0', code: item.isocode };
    });
    CurrencyService.postSellCurrencies(currentCustomer?.id || 1, codes)
      .then((data) => console.log(data))
      .catch((error) => {
        console.log(error);
        setTarget([]);
      });
  };

  const getBuyCurrency = () => {
    CurrencyService.getBuyCurrencies(currentCustomer?.id || 1)
      .then((data: string[]) => {
        updateValues(data);
      })
      .catch((error) => {
        console.log(error);
        setTarget([]);
      });
  };

  const getSellCurrency = () => {
    CurrencyService.getSellCurrencies(currentCustomer?.id || 0)
      .then((data: string[]) => {
        updateValues(data);
      })
      .catch((error) => {
        console.log(error);
        setTarget([]);
      });
  };

  const updateValues = (data: string[]) => {
    const targetList = selectedCurrencies.filter((currency: CurrencyConfiguration) =>
      data.includes(currency.isocode),
    );
    const sourceList = selectedCurrencies.filter(
      (currency: CurrencyConfiguration) => !data.includes(currency.isocode),
    );
    setTarget(targetList);
    setSource(sourceList);
  };

  useEffect(() => {
    setSource([]);
    setTarget([]);
    if (!isSellOption) getBuyCurrency();
    else getSellCurrency();
  }, [isSellOption]);

  return (
    <div className='bg-white p-6 rounded-c8 border border-neutral-surface-dark'>
      <div className='flex justify-center'>
        <Select
          options={CURRENCY_ACTIONS.map((action) => ({
            ...action,
            name: translateWithValues(action.name),
          }))}
          value={currencyAction}
          onChange={handleOptionChange}
        />
      </div>
      <div className='flex justify-center pt-6'>
        <div className='p-6 border border-neutral-border rounded-c8 w-4/5'>
          <CurrencyPickList
            source={source}
            target={target}
            onChange={handleChange}
            sourceHeader={translateWithValues(
              isSellOption ? 'sell_currency_available' : 'buy_currency_available',
            )}
            destinationHeader={translateWithValues(
              isSellOption ? 'sell_currency_allowed' : 'buy_currency_allowed',
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailableCurrencies;
