import { Checkmark } from '@carbon/icons-react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';

interface PermissionCardProps {
  permissionName: string;
  actions: string[];
  selectedHeaders: Record<string, boolean>;
  selectedPermissions: string[];
  onHeaderChange: (e: CheckboxChangeEvent, headerName: string) => void;
  onPermissionChange: (e: CheckboxChangeEvent, permissionName: string, action: string) => void;
  readOnly?: boolean;
}

const PermissionCard = ({
  permissionName,
  actions,
  selectedHeaders,
  selectedPermissions,
  onHeaderChange,
  onPermissionChange,
  readOnly,
}: PermissionCardProps) => {
  return (
    <div className='card flex flex-col w-[21rem]'>
      <div className='flex items-center mb-6 rounded bg-primary-surface-blue-light p-2'>
        {!readOnly ? (
          <Checkbox
            inputId={`header-${permissionName}`}
            checked={selectedHeaders[permissionName] ?? false}
            onChange={(e) => onHeaderChange(e, permissionName)}
          />
        ) : (
          <Checkmark fill='var(--checkmark-fill)' />
        )}
        <label htmlFor={`header-${permissionName}`} className='ml-2 font-semibold'>
          {permissionName}
        </label>
      </div>
      <div className='p-2 flex flex-col gap-6'>
        {actions.map((action, actionIndex) => (
          <div key={actionIndex} className='flex items-center gap-3'>
            {!readOnly ? (
              <Checkbox
                inputId={`${permissionName}-${action}`}
                checked={selectedPermissions.includes(`${permissionName}-${action}`)}
                onChange={(e) => onPermissionChange(e, permissionName, action)}
              />
            ) : (
              <Checkmark fill='var(--checkmark-fill)' />
            )}
            <label htmlFor={`${permissionName}-${action}`} className='ml-2 font-sm-semibold'>
              {action}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PermissionCard;
