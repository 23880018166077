import { CloseOutline, Redo } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CurrencyFlag, InheritedMenu, PaginatorTemplate, Status } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { MoreAction } from '../../../../icons';
import { useAppDispatch } from '../../../../store';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { fetchCurrency } from '../../../../store/slices/refdata/actions';
import { selectCustomerCurrencies } from '../../../../store/slices/refdata/refdataSlice';
import CurrencyService from './currencyService';

const CurrencyManagement = () => {
  const [selectedCurrencies, setSelectCurrencies] = useState<CurrencyConfiguration[] | null>(null);
  const [currentActions, setCurrentActions] = useState<MenuItem[]>([]);
  const [currentActionCurrency, setCurrentActionCurrency] = useState<CurrencyConfiguration | null>(
    null,
  );
  const menu = useRef<Menu>(null);
  const dispatch = useAppDispatch();
  const currentCustomer = useSelector(selectCurrentCustomer);
  const currencies = useSelector(selectCustomerCurrencies);
  useEffect(() => {
    getCurrencies();
  }, [currentCustomer, dispatch]);

  const getCurrencies = () => {
    const customerId = currentCustomer?.id;
    if (!customerId) {
      return;
    }
    dispatch(fetchCurrency(customerId));
  };

  const enableCurrency = (items: CurrencyConfiguration[] | null) => {
    if (items) {
      const codes: any = items.map((item: CurrencyConfiguration) => {
        return { id: '0', code: item.isocode };
      });
      CurrencyService.enableCurrency(currentCustomer?.id || 1, codes).then(() => {
        getCurrencies();
      });
    }
  };

  const disableCurrency = (items: CurrencyConfiguration[] | null) => {
    if (items) {
      const codes: any = items.map((item: CurrencyConfiguration) => {
        return { id: '0', code: item.isocode };
      });
      CurrencyService.disableCurrency(currentCustomer?.id || 1, codes).then(() => {
        getCurrencies();
      });
    }
  };

  const actions: MenuItem[] = [
    {
      label: 'enableCurrency',
      onClick: () => {
        currentActionCurrency && enableCurrency([currentActionCurrency]);
      },
      icon: <Redo />,
    },
    {
      label: 'disableCurrency',
      onClick: () => {
        currentActionCurrency && disableCurrency([currentActionCurrency]);
      },
      icon: <CloseOutline />,
    },
  ];

  const actionBodyTemplate = (currency: CurrencyConfiguration) => {
    const actionFilter: MenuItem[] = actions.filter((item) => {
      if (currency.disabled === false && item.label === 'enableCurrency') {
        return false;
      }
      if (currency.disabled === true && item.label === 'disableCurrency') {
        return false;
      }
      return true;
    });
    return (
      <div>
        <div
          onClick={(event) => {
            setCurrentActions(actionFilter);
            setCurrentActionCurrency(currency);
            menu.current?.toggle(event);
          }}
        >
          <MoreAction />
        </div>
        <InheritedMenu items={currentActions} ref={menu} popupAlign='left' />
      </div>
    );
  };

  return (
    <div className='table-card'>
      {currencies.length != 0 && (
        <DataTable
          paginator={true}
          paginatorTemplate={PaginatorTemplate}
          first={0}
          rows={10}
          totalRecords={currencies.length}
          header={
            <div className='flex justify-between'>
              <div className='text-neutral-1 text-lg-semibold'>
                Total
                <span className='ml-1 text-xs-medium px-[8px] py-[2px] rounded-[16px] bg-neutral-surface-dark'>
                  {currencies.length}
                </span>
              </div>
              {selectedCurrencies && selectedCurrencies?.length > 0 && (
                <div className='flex gap-2'>
                  <Button severity='contrast' onClick={() => enableCurrency(selectedCurrencies)}>
                    <Translate value='enable' />
                  </Button>
                  <Button severity='contrast' onClick={() => disableCurrency(selectedCurrencies)}>
                    <Translate value='disable' />
                  </Button>
                </div>
              )}
            </div>
          }
          value={currencies}
          size={'large'}
          selectAll={true}
          selectionMode={'checkbox'}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selection={selectedCurrencies!}
          onSelectionChange={(e: { value: CurrencyConfiguration[] }) =>
            setSelectCurrencies(e.value)
          }
          dataKey='id'
        >
          <Column selectionMode='multiple' headerStyle={{ width: '1rem' }}></Column>
          <Column
            field='isocode'
            header='Currency'
            body={(currency: CurrencyConfiguration) => (
              <div className='text-sm-medium text-neutral-2'>
                <CurrencyFlag currencyCode={currency.isocode} />
              </div>
            )}
          ></Column>
          <Column
            field='status'
            header='Status'
            body={(currency: CurrencyConfiguration) => (
              <Status status={currency.disabled ? 'INACTIVE' : 'ACTIVE'} />
            )}
          ></Column>
          <Column
            field='ratePrecision'
            header='Rate Precision'
            body={(currency: CurrencyConfiguration) => <div>{currency.ratePrecision}</div>}
          ></Column>
          <Column
            field='amountPrecision'
            header='Amount Precision'
            body={(currency: CurrencyConfiguration) => <div>{currency.amountPrecision}</div>}
          ></Column>
          <Column
            field='minAmount'
            header='Minimum Amount'
            body={(currency: CurrencyConfiguration) => <div>{currency.minAmount?.value}</div>}
          ></Column>
          <Column
            field='maxAmount'
            header='Maximum Amount'
            body={(currency: CurrencyConfiguration) => <div>{currency.maxAmount?.value}</div>}
          ></Column>
          <Column header='Actions' body={actionBodyTemplate}></Column>
        </DataTable>
      )}
    </div>
  );
};

export default CurrencyManagement;
