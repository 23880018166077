import { Toast } from 'primereact/toast';
import { forwardRef, ReactNode, useImperativeHandle, useRef } from 'react';
import { CheckMark } from '../../icons';
import ErrorIcon from '../ErrorIcon';
import './toaster.scss';

const Toaster = forwardRef<ToasterProps>((props, ref) => {
  const toast = useRef<Toast>(null);

  useImperativeHandle(ref, () => ({
    showToast: (
      severity: ToastSeverity,
      summary: string,
      detail: string,
      life = 3000,
      extraContent: ReactNode,
    ) => {
      toast.current?.show({
        summary,
        detail,
        life,
        content: (
          <div className='flex items-start'>
            {severity == 'success' ||
              (severity == 'info' && <CheckMark type={severity} size={22} />)}
            {severity == 'error' && <ErrorIcon />}
            <div className='ml-3'>
              <h4 className='text-sm-semibold text-neutral-1'>{summary}</h4>
              <p className='mt-1 text-sm text-neutral-3'>{detail}</p>
              {extraContent}
            </div>
          </div>
        ),
      });
    },
  }));

  return <Toast ref={toast} className='custom-toast' position='bottom-right' />;
});

Toaster.displayName = 'Toaster';
export default Toaster;
