import { TabView, TabPanel } from 'primereact/tabview';
import CustomRoles from './CustomRoles';
import PrimaryRoles from './PrimaryRoles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // Create an instance of URLSearchParams to extract query parameters
    const searchParams = new URLSearchParams(location.search);

    // Check if the 'type' query parameter is 'custom'
    if (searchParams.get('type') === 'custom') {
      setActiveIndex(1); // Second tab (index 1) will be selected
    } else {
      setActiveIndex(0); // Default to the first tab
    }
  }, [location.search]);

  const handleTabChange = (e: { index: number }) => {
    setActiveIndex(e.index);

    // Update the URL based on the selected tab
    if (e.index === 0) {
      navigate('/manage/role?type=primary');
    } else if (e.index === 1) {
      navigate('/manage/role?type=custom');
    }
  };

  return (
    // <div className='card'>
    <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
      <TabPanel header='Primary roles'>
        <PrimaryRoles />
      </TabPanel>
      <TabPanel header='Custom roles'>
        <CustomRoles />
      </TabPanel>
    </TabView>
    // </div>
  );
}
