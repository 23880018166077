import { CheckboxChangeEvent } from 'primereact/checkbox';
import { User } from './index';

export const onSelectAllChange = (
  e: CheckboxChangeEvent,
  setSelectedAll: (selected: boolean) => void,
  setSelectedUsers: (users: User[]) => void,
  users: User[],
) => {
  const checked = e.checked ?? false;
  setSelectedAll(checked);
  if (checked) {
    setSelectedUsers(users);
  } else {
    setSelectedUsers([]);
  }
};

export const goToNextPage = (
  currentPage: number,
  setCurrentPage: (page: number | ((prev: number) => number)) => void,
  totalPages: number,
) => {
  if (currentPage < totalPages) {
    setCurrentPage((prev) => prev + 1);
  }
};

export const goToPreviousPage = (
  currentPage: number,
  setCurrentPage: (page: number | ((prev: number) => number)) => void,
) => {
  if (currentPage > 1) {
    setCurrentPage((prev) => prev - 1);
  }
};
