import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import InfoTemplate from '../../../components/InfoTemplate';
import { Translate } from '../../../i18n/translate';
import { PageHeadContext } from '../../../providers/PageHead';
import ClientService from '../clientService';
import Table from './Table';

const Dashboard = () => {
  const navigate = useNavigate();
  const [clientList, setCLientList] = useState<Client[]>([]);
  const context = useContext(PageHeadContext);
  const { setPageHeadData } = context;
  const [isLoading, setLoading] = useState(true);
  const { customerId } = useParams();

  useEffect(() => {
    getClients();
  }, [customerId]);

  const getClients = async () => {
    if (customerId) {
      const childClients = await ClientService.getChildClients(customerId);
      const filteredClients = childClients.filter((client) => !client.isDeleted);
      setCLientList(filteredClients);
    } else {
      const clients = await ClientService.getClients();
      const filteredClients = clients.filter((client) => !client.isDeleted);
      setCLientList(filteredClients);
    }
    setLoading(false);
  };

  useEffect(() => {
    setPageHeadData({
      title: 'client.list.title',
      description: 'client.list.description',
      className: 'pb-[16px] pr-[60px]',
      createUI:
        clientList.length != 0 ? (
          <InfoTemplate
            titleKey='client.addnew.title'
            descriptionKey='client.addnew.description'
            buttonKey='client.addnew.button'
            btnAction={() => navigate('/client/add')}
          />
        ) : (
          <></>
        ),
    });
  }, [clientList]);

  return (
    <>
      <div className='mt-[28px]'>
        {!isLoading && clientList.length === 0 && (
          <div className='card'>
            <div className='text-center w-[54%] m-auto'>
              <img src='/images/study--skip.svg' className='m-auto pt-[60px]' />
              <div className='text-md-bold neutral-1 mt-5'>
                <Translate value='client.noclients.title' />
              </div>
              <div className='text-md-regular text-neutral-3 mt-3'>
                <Translate value='client.noclients.description' />
              </div>
            </div>
            <div className='mt-[30px]'>
              <InfoTemplate
                titleKey='client.addnew.title'
                descriptionKey='client.addnew.description'
                buttonKey='client.addnew.button'
                btnAction={() => navigate('/client/add')}
              />
            </div>
          </div>
        )}
      </div>

      <div className='mt-[50px]'>
        <Table clients={clientList} getClients={getClients} />
      </div>
    </>
  );
};

export default Dashboard;
