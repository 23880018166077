import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import oktaAuth from '../auth/Okta';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: false,
});

let customerId: number | null = null;

export const injectCustomerID = (cId: number) => {
  customerId = cId;
};

apiClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    const token = await oktaAuth.tokenManager.get('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${(token as any).accessToken}`;
    }
    if (customerId) {
      config.headers['owningCustomerID'] = customerId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return {
      success: true,
      ...response,
    };
  },
  (error) => {
    console.debug('API Error:', error);
    return {
      success: false,
      ...error,
    };
  },
);

export default apiClient;
