import { Add } from '@carbon/icons-react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CurrencySelect, DropdownInput, TextInput } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';
import { MarginType } from '../../../../types';
import { MARGIN_TYPE_OPTIONS } from '../Dashboard/formConstants';
import ManagePricingService from '../managePricingService';
import './../index.css';

const CreateMargin = ({
  visible,
  setCreateMarginToggle,
  editEntity,
}: {
  visible: boolean;
  editEntity?: Margin;
  setCreateMarginToggle: (visible: boolean) => void;
}) => {
  const {
    unregister,
    watch,
    setValue,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<CreateMarginFormData>();
  const formRef = useRef(null);
  const currentCustomer = useSelector(selectCurrentCustomer);
  const marginType = watch('marginType');
  const [tiers, setTiers] = useState([
    { tier: 'Tier 1', margin: '', minAmount: '', maxAmount: '' },
  ]);

  const handleAddTier = () => {
    setTiers([
      ...tiers,
      { tier: `Tier ${tiers.length + 1}`, margin: '', minAmount: '', maxAmount: '' },
    ]);
  };

  const handleInputChange = (index: number, event: any) => {
    const updatedTiers: any = [...tiers];
    updatedTiers[index][event.target.name] = event.target.value;
    setTiers(updatedTiers);
  };

  const [formdata, setFormData] = useState({
    margin: '',
    minAmount: '',
    maxAmount: '',
  });

  useEffect(() => {
    if (editEntity) {
      setValue('marginType', editEntity.marginType);
      setValue('description', editEntity.description);
      setValue(
        'currencyList',
        marginType == MarginType.CURRENCY ? editEntity.currencyList[0] : editEntity.currencyList,
      );
      setValue('margin', editEntity.managedMarginList[0].margin);
      setFormData((prev) => ({
        ...prev,
        minAmount: `${editEntity.managedMarginList[0].minThreshold}`,
        maxAmount: `${editEntity.managedMarginList[0].maxThreshold}`,
      }));
    }
    if (marginType == MarginType.TIRED) {
      unregister('margin');
      if (editEntity?.managedMarginList) {
        const populatedTiers = populateFormWithManagedMarginList(editEntity.managedMarginList);
        setTiers(populatedTiers);
      }
    }
  }, [editEntity, marginType]);

  const populateFormWithManagedMarginList = (managedMarginList: any[]) => {
    return managedMarginList.map((item) => ({
      tier: item.tierName ?? '',
      margin: `${item.margin}`,
      minAmount: item.minThreshold,
      maxAmount: item.maxThreshold,
    }));
  };

  const createTieredMarginList = () => {
    return tiers.map(
      (tier: { tier: any; margin: string; minAmount: string; maxAmount: string }) => ({
        tierName: tier.tier,
        margin: parseFloat(tier.margin),
        minThreshold: parseFloat(tier.minAmount),
        maxThreshold: parseFloat(tier.maxAmount),
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const onSave = async (data: CreateMarginFormData) => {
    const updateData = {
      ...data,
      currencyList: marginType == MarginType.CURRENCY ? [data.currencyList] : data.currencyList,
      managedMarginList:
        marginType != MarginType.TIRED
          ? [
              {
                margin: data.margin,
                minThreshold: formdata.minAmount,
                maxThreshold: formdata.maxAmount,
              },
            ]
          : createTieredMarginList(),
      owningCustomerId: currentCustomer?.id,
    };
    if (currentCustomer?.id) {
      if (!editEntity) {
        await ManagePricingService.createMargin(currentCustomer.id, updateData);
      } else {
        await ManagePricingService.updateMargin(editEntity.id, updateData);
      }
    }
    setCreateMarginToggle(false);
    return Promise.resolve();
  };

  const headerElement = () => (
    <div className='flex justify-between items-center w-full'>
      <div className='flex flex-col gap-1'>
        <div className='text-lg font-semibold text-neutral-1'>
          <Translate value='createMarginTitle' />
        </div>
        <div className='text-sm font-regular text-neutral-3'>
          <Translate value='createMarginDescription' className='!text-sm-regular' />
        </div>
      </div>
    </div>
  );
  const footerContent = (
    <div className='flex justify-end mt-5 gap-2'>
      <Button severity='secondary' onClick={() => setCreateMarginToggle(false)}>
        <Translate value='cancel' />
      </Button>

      <Button
        severity='info'
        onClick={() => {
          if (formRef.current) {
            (formRef.current as HTMLFormElement).dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true }),
            ); // Manually trigger form submit
          }
        }}
      >
        <Translate value='save' />
      </Button>
    </div>
  );

  return (
    <Dialog
      className='w-[30%] scale max-h-[100%] transform scale-100 m-0 !rounded-none full-screen-dialog'
      header={headerElement}
      visible={visible}
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
      onHide={() => setCreateMarginToggle(false)}
      footer={footerContent}
    >
      {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit(onSave)} ref={formRef}>
          <DropdownInput
            label='marginType'
            placeholder='marginType'
            name='marginType'
            disabled={!!editEntity}
            isRequired
            control={control}
            options={MARGIN_TYPE_OPTIONS}
            rules={{ required: true }}
            error={errors['marginType']}
          />

          <TextInput
            control={control}
            className='mt-[32px]'
            label='description'
            placeholder='description'
            isRequired
            formRegister={register('description', { required: true })}
            error={errors['description']}
          />

          {marginType !== MarginType.CURRENCY && (
            <CurrencySelect
              control={control}
              className='mt-[32px] w-full'
              name='currencyList'
              label='ccy'
              placeholder='ccy'
              rules={{ required: true }}
              error={errors['currencyList']}
              isRequired
              isMulti={true}
              includeAll={true}
            />
          )}

          {marginType === MarginType.CURRENCY && (
            <CurrencySelect
              control={control}
              className='mt-[32px] w-full'
              name='currencyList'
              label='ccy'
              placeholder='ccy'
              rules={{ required: true }}
              error={errors['currencyList']}
              isRequired
            />
          )}

          {marginType !== MarginType.TIRED && (
            <TextInput
              control={control}
              className='mt-[32px]'
              name='margin'
              label='margin'
              isRequired
              formRegister={register('margin', { required: true })}
              error={errors['margin']}
            />
          )}

          {marginType === MarginType.TIRED && (
            <>
              {tiers.map((tier, index) => (
                <div key={index} className='grid grid-cols-4 gap-1 mb-4'>
                  <TextInput
                    name='tier'
                    label='Tier'
                    placeholder='Tier name'
                    value={tier.tier}
                    onChange={(e) => handleInputChange(index, e)}
                  />

                  <TextInput
                    type='text'
                    name='margin'
                    label=''
                    value={tier.margin}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder='Margin'
                    className='mt-7'
                  />

                  <TextInput
                    type='text'
                    name='minAmount'
                    value={tier.minAmount}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder='Min Amount'
                    className='mt-7'
                  />

                  <TextInput
                    type='text'
                    name='maxAmount'
                    value={tier.maxAmount}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder='Max Amount'
                    className='mt-7'
                  />
                </div>
              ))}
              <a onClick={handleAddTier} className='pointer text-primary text-sm-semibold'>
                <Add className='mr-1 inline' /> Add Tier
              </a>
            </>
          )}

          {marginType !== MarginType.TIRED && (
            <div className='mt-[32px]'>
              <label>
                <Translate value='amount' />
              </label>
              <div className='flex'>
                <TextInput
                  id='minAmount'
                  name='minAmount'
                  value={formdata.minAmount}
                  onChange={(e: { target: { value: any } }) => {
                    setFormData({ ...formdata, minAmount: e.target.value });
                  }}
                />
                <div className='ml-2 mr-2 pt-2'> - </div>

                <TextInput
                  id='maxAmount'
                  name='maxAmount'
                  value={formdata.maxAmount}
                  onChange={(e: { target: { value: any } }) => {
                    setFormData({ ...formdata, maxAmount: e.target.value });
                  }}
                />
              </div>
            </div>
          )}
        </form>
      }
    </Dialog>
  );
};

export default CreateMargin;
