import { Help, Settings, SidePanelClose, SidePanelOpen } from '@carbon/icons-react';
import { Ripple } from 'primereact/ripple';
import { Sidebar as PSideBar } from 'primereact/sidebar';
import { StyleClass } from 'primereact/styleclass';
import React, { ReactElement, createRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from '../../i18n/translate';
import menuItems from './menuItems';
import './sidebar.css';

interface SidebarProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MenuItem {
  key: string;
  link?: string;
  icon?: ReactElement; // For React JSX elements like icons
  children?: MenuItem[]; // Recursive type for nested menu items
}

const Sidebar: React.FC<SidebarProps> = ({ visible, setVisible }) => {
  const refs = useRef<any | null>(menuItems.map(() => createRef()));

  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(null);
  const [selectedChildItem, setSelectedChildItem] = useState<MenuItem | null>(null);

  const toggleSidebar = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (visible) {
      const selectedIndex = menuItems.findIndex((e) => e.key === selectedItem?.key);
      if (selectedIndex !== -1 && refs.current[selectedIndex]) {
        // When going back from collapsed mode, selected the nested drawer item
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          refs.current[selectedIndex].current.click();
        }, 100);
      }
    }
  }, [visible]);

  const handleMenuItemClick = (item: MenuItem, child?: MenuItem) => {
    setSelectedItem(item);
    setSelectedChildItem(null);
    if (item.children && item?.children.length > 0 && child) {
      setSelectedChildItem(child);
    }
    // Applicable for item with child items in collapsed view
    if (item.children && item?.children?.length > 0 && !visible) {
      setVisible(true);
    }
  };

  const renderMenuItems = (items: typeof menuItems) =>
    items.map((item, index) => {
      const hasChildren = item.children && item.children.length > 0;
      return (
        <li key={item.key}>
          {hasChildren && visible ? (
            <>
              <StyleClass
                nodeRef={refs.current[index]}
                selector='@next'
                enterClassName='hidden'
                enterActiveClassName='slidedown'
                leaveToClassName='hidden'
                leaveActiveClassName='slideup'
              >
                <a
                  ref={refs.current[index]}
                  className={`menu-button p-ripple hover:surface-100 transition-duration-150 transition-colors ${!visible && `justify-center ${selectedItem?.key === item.key && 'menu-item-selected'}`}`}
                >
                  {item.icon}

                  <>
                    <span className='ml-2'>
                      <Translate value={item.key} />
                    </span>
                    <i
                      className={`pi ${item?.children.length ? 'pi-angle-down' : ''} ml-auto mr-1`}
                    />
                    <Ripple />
                  </>
                </a>
              </StyleClass>

              <ul
                className={`list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out ${!visible && 'h-0'}`}
              >
                {item.children?.map((child) => (
                  <li key={child.key} onClick={() => handleMenuItemClick(item, child)}>
                    <Link
                      to={child.link}
                      className={`menu-button p-ripple hover:surface-100 transition-duration-150 transition-colors ${selectedChildItem?.key === child.key && 'menu-item-selected'}`}
                    >
                      <span className='ml-9 text-md-regular'>
                        <Translate value={child.key} />
                      </span>
                      <Ripple />
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <Link
              to={item.link || '#'}
              className={`menu-button ${!visible && 'justify-center'} ${selectedItem?.key === item.key && 'menu-item-selected'}`}
              onClick={() => handleMenuItemClick(item)}
            >
              <div onClick={() => handleMenuItemClick(item)}>{item.icon}</div>
              {visible && (
                <>
                  <span className='ml-2'>
                    <Translate value={item.key} />
                  </span>
                  <i className={`pi ${hasChildren ? 'pi-angle-down' : ''} ml-auto mr-1`} />
                </>
              )}
            </Link>
          )}
        </li>
      );
    });

  return (
    <PSideBar
      modal={false}
      dismissable={false}
      closeOnEscape={false}
      blockScroll={true}
      visible={true}
      position='left'
      onHide={() => {
        setVisible(false);
      }}
      pt={{
        mask: {
          className: visible ? '!w-[312px]' : '!w-24',
        },
      }}
      className={visible ? '!w-[312px]' : '!w-24'}
      content={() => (
        <div
          className={`flex flex-col min-h-screen bg-primary-dark py-[32px] text-white ${visible ? 'px-[24px]' : 'px-[16px]'}`}
        >
          {visible ? (
            <div className='flex justify-between'>
              <div>
                <img
                  src='/images/logo_stonex.png'
                  style={{ width: '166.67px', height: '20px' }}
                  alt='logo'
                />
              </div>

              <div
                className='cursor-pointer'
                onClick={() => {
                  setVisible(false);
                }}
              >
                <SidePanelClose size={20} />
              </div>
            </div>
          ) : (
            <>
              <div className='flex flex-col items-center gap-9'>
                <img
                  src='/images/logo_stonex_collapsed.png'
                  style={{ width: '25px', height: '22px' }}
                  alt='logo'
                />

                <div onClick={toggleSidebar} className='cursor-pointer'>
                  <SidePanelOpen size={24} />
                </div>
              </div>
            </>
          )}

          <div className='flex-grow flex flex-col justify-between border-b border-menu-border'>
            <ul className='list-none m-0 pt-8 overflow-hidden text-md-semibold'>
              {renderMenuItems(menuItems)}
            </ul>
            <ul className='list-none m-0 pt-8 overflow-hidden text-white text-md-semibold pb-5'>
              <li>
                <a className={`menu-button ${!visible && 'justify-center'}`}>
                  <Help size={24} />
                  {visible && (
                    <span className='ml-2'>
                      <Translate value='support' />
                    </span>
                  )}
                </a>
              </li>
              <li>
                <a className={`menu-button ${!visible && 'justify-center'}`}>
                  <Settings size={24} />
                  {visible && (
                    <span className='ml-2'>
                      <Translate value='settings' />
                    </span>
                  )}
                </a>
              </li>
            </ul>
          </div>
          <div className={`flex align-center pt-10 gap-2 ${!visible && 'justify-center'}`}>
            <div>
              <img
                src='/images/profile_image_placeholder.jpg'
                className='w-10 h-10 rounded-full border border-white'
              />
            </div>
            {visible && (
              <div className='text-sm'>
                <div className='font-bold'>Jane Doe</div>
                <div className='font-light'>janedoe@stonex.com</div>
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default Sidebar;
