import { ArrowLeft, ArrowRight } from '@carbon/icons-react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { useEffect, useMemo, useState } from 'react';
import { Translate } from '../../i18n/translate';
import UserPermissionModalHeader from './userModalHeader';
// import UserPermissionModalFooter from './userModalFooter';
import { Button } from 'primereact/button';

import { useSelector } from 'react-redux';
import RoleService from '../../modules/Manage/Role/roleService';
import UserService from '../../modules/Manage/userService';
import { selectCurrentCustomer } from '../../store/slices/main/mainSlice';
import { selectSelectedRole } from '../../store/slices/role/roleSlice';
import SearchBar from '../SearchInput/SearchInput';
import { goToNextPage, goToPreviousPage, onSelectAllChange } from './userPermissionUtils';

interface UserPermissionModalProps {
  visible: boolean;
  onHide: () => void;
  title: string;
}

export interface User {
  name: string;
  email: string;
  isSelf?: boolean;
}

const UserPermissionModal = ({ visible, onHide, title }: UserPermissionModalProps) => {
  const selectedRole = useSelector(selectSelectedRole);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [users, setUsers] = useState<User[]>([]);
  const customer = useSelector(selectCurrentCustomer);

  const USERS_PER_PAGE = 6;
  const totalPages = Math.ceil(users.length / USERS_PER_PAGE);

  useEffect(() => {
    fetchUsers();
  }, [customer?.id]);

  const fetchUsers = async () => {
    if (!customer?.id) return;

    try {
      const response = await UserService.getCustomerUser(customer.id.toString());
      if (response) {
        const extractedUsers: User[] = response.content.map((user: any) => ({
          name: `${user.userIdentity.firstName} ${user.userIdentity.lastName}`,
          email: user.userIdentity.email,
          isSelf: user.isSelf || false,
          id: user.id,
        }));
        setUsers(extractedUsers);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const onUserSelectChange = (e: CheckboxChangeEvent, user: User) => {
    const isChecked = e.checked ?? false;
    if (isChecked) {
      setSelectedUsers((prev) => [...prev, user]);
    } else {
      setSelectedUsers((prev) => prev.filter((u) => u !== user));
    }
  };

  const submitSelection = async () => {
    try {
      const submitData = {
        roleId: selectedRole?.id,
        userIds: selectedUsers.map((e: any) => e?.id),
        asAobo: true,
      };

      const { data, status } = await RoleService.assignUsersToRole(submitData as AssignRolePayload);
      //TODO: Remove data if not required
      console.log(data);
      if (status !== 200) {
        console.log('error');
        return;
      }

      onHide();
    } catch (error) {
      console.error('Error submitting selection', error);
    }
  };

  // Filter users based on search query
  const filteredUsers = useMemo(() => {
    return users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, users]);

  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * USERS_PER_PAGE,
    currentPage * USERS_PER_PAGE,
  );

  const renderFooter = () => {
    return (
      <div className='flex flex-col justify-center items-center w-full'>
        <div className='w-40'>
          <Button
            className='w-full justify-center mb-2'
            severity='info'
            onClick={() => void submitSelection()}
          >
            <Translate value='save_selection' />
          </Button>
          <Button className='w-full justify-center' severity='contrast' onClick={onHide}>
            <Translate value='button_cancel' />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      header={<UserPermissionModalHeader title={title} />}
      visible={visible}
      style={{ width: '25vw', height: '100vh', position: 'fixed', top: 0, right: 0 }}
      onHide={onHide}
      draggable={false}
      className='max-h-[100%] full-screen-dialog'
      footer={renderFooter()}
    >
      <div className='w-full'>
        <SearchBar onSearch={handleSearch} />
      </div>
      <div className='card !p-3 flex flex-col mt-3'>
        <div className='bg-primary-surface-blue-light py-3 pl-2 mb-2 rounded-c8 text-sm-semibold'>
          <Checkbox
            className='!mt-[3px]'
            onChange={(e) => onSelectAllChange(e, setSelectedAll, setSelectedUsers, users)}
            checked={selectedAll}
          ></Checkbox>
          <label htmlFor='selectAll' className='ml-2 text-sm-semibold text-neutral-2'>
            <Translate value='select_permission' />
          </label>
        </div>
        <div className='transition-opacity duration-300 opacity-100'>
          {paginatedUsers.length > 0 ? (
            paginatedUsers.map((user, index) => (
              <div key={index} className='flex gap-4 mt-4 pl-2'>
                <Checkbox
                  className='!mt-[3px]'
                  onChange={(e) => onUserSelectChange(e, user)}
                  checked={selectedUsers.includes(user)}
                />
                <div>
                  <div className='text-sm-medium text-neutral-2'>
                    {user.name}{' '}
                    {user.isSelf && <span className='text-success-green'>| Yourself</span>}
                  </div>
                  <div className='text-sm-regular text-neutral-3'>{user.email}</div>
                </div>
              </div>
            ))
          ) : (
            <div className='text-center text-neutral-3'>No users found</div>
          )}
        </div>
      </div>

      <div className='flex justify-between items-center mt-4 border-t-[1px] border-neutral-border pt-4'>
        <div className='border-[1px] rounded border-neutral-border p-2 ml-3 cursor-pointer'>
          <ArrowLeft onClick={() => goToPreviousPage(currentPage, setCurrentPage)} />
        </div>
        <div className='text-sm text-neutral-3'>
          Page {currentPage} of {totalPages}
        </div>
        <div className='border-[1px] rounded border-neutral-border p-2 mr-3 cursor-pointer'>
          <ArrowRight onClick={() => goToNextPage(currentPage, setCurrentPage, totalPages)} />
        </div>
      </div>
    </Dialog>
  );
};

export default UserPermissionModal;
