import { InputSwitch } from 'primereact/inputswitch';
import { translateWithValues } from '../../i18n/translate';

interface ToggleInputProps {
  id: string;
  label: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  checked: boolean;
  className?: string;  // Changed `class` to `className` as `class` is a reserved keyword in JavaScript/TypeScript
}

const ToggleInput = ({ id, label, isRequired, ariaDescribedBy, checked, className }: ToggleInputProps) => (
  <div className={className ? className : 'flex flex-col gap-1'}>
    <label htmlFor={id}>
      {translateWithValues(label)} {isRequired && <span className='text-error-1'>*</span>}
    </label>
    <InputSwitch id={id} checked={checked} aria-describedby={ariaDescribedBy} />
  </div>
);

export default ToggleInput;
