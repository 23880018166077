import { useEffect, useState } from 'react';
import InfoTemplate from '../../../../components/InfoTemplate';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import { useNavigate } from 'react-router';
import PermissionModal from '../../../../components/PermissionModal';
import RoleService from '../roleService';
import RolesTable from './RolesTable';
import { useSelector } from 'react-redux';
import { selectCurrentCustomer } from '../../../../store/slices/main/mainSlice';

const PrimaryRoles = () => {
  const navigate = useNavigate();
  const currentCustomer = useSelector(selectCurrentCustomer);
  const [isModalVisible, setModalVisible] = useState(false);
  // const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [primaryRoles, setPrimaryRoles] = useState<Role[]>([]);
  const [customRoles, setCustomRoles] = useState<Role[]>([]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    const roles = await RoleService.getCustomerRoles(currentCustomer?.id);
    setPrimaryRoles(roles.primaryRoles);
    setCustomRoles(roles.customRoles);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  if (!primaryRoles.length && !customRoles.length) {
    return (
      <>
        {/* <RoleDefinitionCard /> */}
        <NoDataPlaceholder title='roles.noRoles.title' desc='roles.noRoles.desc'>
          <InfoTemplate
            titleKey='roles.noRoles.info.title'
            descriptionKey='roles.noRoles.info.desc'
            buttonKey='roles.noRoles.info.button.title'
            btnAction={() => console.log('TODO')}
          />
        </NoDataPlaceholder>
      </>
    );
  }

  return (
    <div>
      <PermissionModal visible={isModalVisible} onHide={handleCloseModal} permissions={[]} />
      <RolesTable
        title='roles.primary.title'
        description='roles.primary.description'
        data={primaryRoles}
      />
      <RolesTable
        title='customeUserTableHead'
        description='customUserTableDesc'
        data={customRoles}
      />

      {/* TODO: Remove if not required */}
      {/* <div className='card'>
        <div className='relative'>
          <div>
            <Translate value='roles.primary.new.title' className='text-xs-bold text-neutral-1' />

            <div className='mt-1'>
              <Translate
                value='roles.primary.new.description'
                className='text-md-regular text-neutral-3'
              />
            </div>
          </div>

          <div className='flex justify-end items-center float-right absolute right-0 top-0'>
            <Progress value={50} currentStep={1} stepLimit={3} />
            <CheckMark className='ml-4' />
          </div>
        </div>

        <div className='flex flex-wrap gap-8 justify-center mt-8 max-w-[1000px] mx-auto'>
          {roles.map((role) => (
            <RoleCard
              key={role.title}
              title={role.title}
              description={role.description}
              selected={selectedRoles.includes(role.title)}
              onSelect={() => handleSelectRole(role.title)}
              handleOpenModal={handleOpenModal}
            />
          ))}
        </div>
      </div> */}

      <div className='mt-5'>
        <InfoTemplate
          titleKey='roles.primary.new.info.title'
          descriptionKey='roles.primary.new.info.desc'
          buttonKey='roles.primary.new.info.button.title'
          btnAction={() => navigate('/manage/role?type=custom')}
        />
      </div>
    </div>
  );
};
export default PrimaryRoles;
