import { Outlet } from 'react-router';
import { PageHeadProvider } from '../../providers/PageHead';

const ClientOutlet = () => {
  // const navigate = useNavigate();

  return (
    <>
      <PageHeadProvider>
        <div className='pl-[49px] pr-[57px]'>
          <Outlet />
        </div>
      </PageHeadProvider>
    </>
  );
};
export default ClientOutlet;
