import { Button } from 'primereact/button';
import { Translate } from '../../i18n/translate';
import { BlueInfo } from '../../icons';

interface InfoTemplateProps {
  titleKey: string;
  descriptionKey: string;
  buttonKey: string;
  btnAction: (...args: any[]) => any;
}

const InfoTemplate = ({ titleKey, descriptionKey, buttonKey, btnAction }: InfoTemplateProps) => {
  return (
    <div className='p-[20px] rounded-[12px] border-[1px] border-primary-blue-light bg-primary-surface-blue-light'>
      <div className='flex justify-between'>
        <div className='flex'>
          <div>
            <BlueInfo />
          </div>
          <div className='ml-3'>
            <Translate value={titleKey} className='block text-sm-semibold text-primary-blue-dark' />
            <Translate
              value={descriptionKey}
              className='block text-sm-regular text-primary-blue-dark mt-1'
            />
          </div>
        </div>
        <div>
          <Button severity='info' onClick={() => btnAction()}>
            <Translate value={buttonKey} />
          </Button>

          {/* <Button severity='secondary' className='ml-3'>
            <Translate value='client.addnew.cancel' />
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default InfoTemplate;
