import { CheckboxChangeEvent } from 'primereact/checkbox';
import { useEffect, useState } from 'react';

export const usePermissionModal = (permissions: RolePermission[]) => {
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [selectedHeaders, setSelectedHeaders] = useState<Record<string, boolean>>({});
  const [isUserPermissionModalVisible, setUserPermissionModalVisible] = useState(false);

  const onSelectAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.checked ?? false;
    setSelectedAll(checked);

    if (checked) {
      const allPermissions = permissions.flatMap((p) => p.actions.map((a) => `${p.feature}-${a}`));
      setSelectedPermissions(allPermissions);
      setSelectedHeaders(
        permissions.reduce(
          (acc, perm) => {
            acc[perm.feature] = true;
            return acc;
          },
          {} as Record<string, boolean>,
        ),
      );
    } else {
      setSelectedPermissions([]);
      setSelectedHeaders({});
    }
  };

  useEffect(() => {
    const allSelected = permissions.every((p) =>
      p.actions.every((a) => selectedPermissions.includes(`${p.feature}-${a}`)),
    );
    setSelectedAll(allSelected);
  }, [selectedPermissions, permissions]);

  const onHeaderChange = (e: CheckboxChangeEvent, headerName: string) => {
    const checked = e.checked ?? false;

    setSelectedHeaders((prev) => ({
      ...prev,
      [headerName]: checked,
    }));

    console.log(selectedPermissions);

    // const newSelectedPermissions = checked
    //   ? [...selectedPermissions, ...actions.map((action) => `${headerName}-${action}`)]
    //   : selectedPermissions.filter((permission) => !permission.startsWith(`${headerName}-`));

    // setSelectedPermissions(newSelectedPermissions);
  };

  const onPermissionChange = (e: CheckboxChangeEvent, permissionName: string, action: string) => {
    const checked = e.checked;
    const permissionKey = `${permissionName}-${action}`;

    setSelectedPermissions((prev) =>
      checked ? [...prev, permissionKey] : prev.filter((name) => name !== permissionKey),
    );
  };

  const onSave = (onHide: () => void) => {
    console.log('Selected Permissions:', selectedPermissions);
    onHide();
    setUserPermissionModalVisible(true);
  };

  const handleCloseModal = () => {
    setUserPermissionModalVisible(false);
  };

  return {
    selectedAll,
    selectedPermissions,
    selectedHeaders,
    isUserPermissionModalVisible,
    onSelectAllChange,
    onHeaderChange,
    onPermissionChange,
    onSave,
    handleCloseModal,
  };
};
