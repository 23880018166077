import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { useState } from 'react';
import { CustomFileUpload, DropdownInput } from '../../../components';
import Progress from '../../../components/Progress';
import { Translate } from '../../../i18n/translate';
import Checkmark from '../../../icons/Checkmark';
import { AddOrEdit, ClientForms } from '../../../types';
import { SSO_PROVIDER_OPTIONS } from '../clientConstants';
import ClientService from '../clientService';
import ColorDetails from './ColorDetails';

interface SiteDetailsProps {
  activeStep?: string;
  visited?: Set<string>;
  onSaveAndProceed?: () => void;
  mode: AddOrEdit;
  visitPermitted: Set<string>;
  jumpToEdit: (step: ClientForms) => void;
}

const SiteDetails = ({
  activeStep,
  onSaveAndProceed,
  visited,
  mode,
  visitPermitted,
  jumpToEdit,
}: SiteDetailsProps) => {
  const [brandingData, setBrandingData] = useState<BrandingFormData>({});

  const handleChange = (e: any, name: string) => {
    setBrandingData({ ...brandingData, [name]: e.target.value });
  };

  const onFileSelected = async (file: File, name: string) => {
    const imageRes = await ClientService.uploadFile(file);
    if (imageRes) {
      const { content, contentType } = imageRes;
      const base64Image = `data:${contentType};base64,${content}`;
      setBrandingData({ ...brandingData, [name]: { content, contentType, preview: base64Image } });
    }
  };

  return (
    <div className='rounded-c8 shadow-c bg-white'>
      <div>
        <div className='grid lg:grid-cols-2 px-8 pt-8'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='site_details.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='site_details.description' />
            </div>
          </div>
          <div className='flex justify-end items-center'>
            {activeStep === ClientForms.SiteDetails && (
              <Progress value={50} currentStep={3} stepLimit={3} />
            )}
            <Checkmark className='ml-4' />
          </div>
        </div>
        {activeStep !== ClientForms.SiteDetails ? (
          <div className='flex justify-between mt-4 px-8 pb-8'>
            <Button
              severity='contrast'
              className='!px-14'
              disabled={mode === AddOrEdit.Add && !visitPermitted?.has(ClientForms.SiteDetails)}
              onClick={() => {
                jumpToEdit(ClientForms.SiteDetails);
              }}
            >
              <Translate value={visited?.has(ClientForms.SiteDetails) ? 'edit' : 'start'} />
            </Button>
            <Progress value={50} currentStep={3} stepLimit={3} />
          </div>
        ) : (
          <>
            <div className='flex px-32 py-6 gap-14 border-b border-banner'>
              <DropdownInput
                name='ssoProvider'
                label='sso_provider'
                placeholder='sso_provider'
                isRequired
                options={SSO_PROVIDER_OPTIONS}
                className='w-80'
                value={brandingData.ssoProvider}
                onChange={handleChange}
              />
              <div className='flex flex-col gap-4 items-center'>
                <label htmlFor='enable_sso'>
                  <Translate value='enable_sso' /> <span className='text-error-1'>*</span>
                </label>
                <InputSwitch
                  id='enable_sso'
                  checked={!!brandingData.enableSso}
                  onChange={(e) => {
                    handleChange(e, 'enableSso');
                  }}
                />
              </div>
            </div>
            <div>
              <div className='flex justify-between border-b-2 border-banner'>
                <div className='p-8 flex gap-4'>
                  <div className='shadow-c py-8 rounded-c8 w-3/5 px-4 lg:px-16'>
                    <div className='text-md-semibold'>
                      <Translate value='site_details.upload_client_logo' />
                    </div>
                    <CustomFileUpload
                      onFilesSelected={(file) => {
                        onFileSelected(file, 'brandingImage');
                      }}
                    />
                  </div>
                  <div className='shadow-c p-8 rounded-c8 w-2/5'>
                    <div className='flex gap-2 mt-2'>
                      <div className='w-80 h-60'>
                        {brandingData.brandingImage && (
                          <img
                            src={brandingData.brandingImage.preview}
                            className='bg-contain w-full h-full'
                          />
                        )}
                      </div>
                    </div>
                    <div className='mt-10'></div>
                  </div>
                </div>
              </div>
              <div className='flex justify-between border-b-2 border-banner'>
                <div className='p-8 flex gap-4'>
                  <div className='shadow-c py-8 rounded-c8 w-3/5 px-4 lg:px-16'>
                    <div className='text-md-semibold'>
                      <Translate value='site_details.upload_client_favicon' />
                    </div>
                    <CustomFileUpload
                      onFilesSelected={(file) => {
                        onFileSelected(file, 'favIcon');
                      }}
                    />
                  </div>
                  <div className='shadow-c p-8 rounded-c8 w-2/5'>
                    <div className='flex gap-2 mt-2'>
                      <div className='w-80 h-60'>
                        {brandingData.favIcon && (
                          <img
                            src={brandingData.favIcon.preview}
                            className='bg-contain w-full h-full'
                          />
                        )}
                      </div>
                    </div>
                    <div className='mt-10'></div>
                  </div>
                </div>
              </div>
              <div className='p-8 flex flex-col gap-10'>
                <ColorDetails />
                <div className='flex justify-between'>
                  <div className='shadow-c w-[90%] rounded-c8 py-8 px-20 flex flex-col gap-4'>
                    <div className='text-md-semibold'>
                      <Translate value='set_font_combinations' />
                    </div>
                    <div>
                      <Button severity='contrast' className='!px-14' disabled={true}>
                        <Translate value='start' />
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Checkmark />
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center pb-8'>
                <Button
                  severity='info'
                  onClick={() => {
                    if (onSaveAndProceed) onSaveAndProceed();
                  }}
                >
                  <Translate value='save_and_proceed' />
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SiteDetails;
