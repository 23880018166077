interface User {
    name: string;
    email: string;
}

interface UserCardProps {
    user: User;
}

const UserCard = ({ user }: UserCardProps) => {
    return (
        <div className='card flex items-center justify-between w-[295px]'>
            <div className='flex items-center pr-3'>
                <div className='mr-4'>
                    <div className='bg-gray-200 rounded-full h-12 w-12 flex items-center justify-center'>
                        <img
                            src='/images/profile_image_placeholder.jpg'
                            className='rounded-full border border-white'
                        />
                    </div>
                </div>
                <div>
                    <h3 className='text-sm-medium font-semibold'>{user.name}</h3>
                    <p className='text-neutral-3 text-sm-regular'>{user.email}</p>
                </div>
            </div>
            <button>✕</button>
        </div>
    );
};

export default UserCard;
